import React, { Component } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import Updater from '../components/Updater'

import Switch from '@material-ui/core/Switch';
import { TextareaAutosize, TextField } from '@material-ui/core';

import { firebaseConfig } from '../constants.js';

import './styles.scss';

export default class Items extends Component {
  firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  db = this.firebaseApp.firestore();

  state = {
    articles: [],
    articleValues: {
      author: '',
      content: '',
      cover_image: '',
      id: '',
      publish: false,
      subTitle: '',
      thumbnail_image: '',
      title: '',
      titleES: '',
    }
  };

  componentDidMount() {
    this.getArticles();
  }

  render () {
    const {
      articles,
      articleValues,
      isLoadingArticles,
      pendingArticleUpdate,
    } = this.state;

    return (
      <div key="news-container" className="news-container">
        <Updater
          id="article-updater"
          className="article-updater"
          fields={[
            {
              id: 'title',
              element: TextField,
              props: {
                className: 'input',
                label: 'Title',
                value: articleValues.title,
                onChange: event => this.handleChange(event, 'title')
              }
            },
            {
              id: 'titleES',
              element: TextField,
              props: {
                className: 'input',
                label: 'Title (Spanish)',
                value: articleValues.titleES,
                onChange: event => this.handleChange(event, 'titleES')
              }
            },
            {
              id: 'subTitle',
              element: TextField,
              props: {
                className: 'input',
                label: 'Subtitle',
                value: articleValues.subTitle,
                onChange: event => this.handleChange(event, 'subTitle')
              }
            },
            {
              id: 'author',
              element: TextField,
              props: {
                className: 'input',
                label: 'Author',
                value: articleValues.author,
                onChange: event => this.handleChange(event, 'author')
              }
            },
            {
              id: 'id',
              element: TextField,
              props: {
                className: 'input',
                label: 'Article ID',
                value: articleValues.id,
                onChange: event => this.handleChange(event, 'id')
              }
            },
            {
              id: 'cover_image',
              element: TextField,
              props: {
                className: 'input',
                label: 'Cover Image URL',
                value: articleValues.cover_image,
                onChange: event => this.handleChange(event, 'cover_image')
              }
            },
            {
              id: 'thumbnail_image',
              element: TextField,
              props: {
                className: 'input',
                label: 'Thumbnail Image URL',
                value: articleValues.thumbnail_image,
                onChange: event => this.handleChange(event, 'thumbnail_image')
              }
            },
            {
              id: 'content',
              element: TextareaAutosize,
              props: {
                onChange: event => this.handleChange(event, 'content'),
                value: articleValues.content,
                rows: 6
              }
            }
          ]}
          submitDisabled={!articleValues.content.length || !articleValues.title.length || !articleValues.author.length}
          onSubmit={this.submitArticle}
          submitContent={<>Submit {pendingArticleUpdate && <i className="material-icons spinner">autorenew</i>}</>}
        />

        <hr />

        <div className="articles-container">
          {isLoadingArticles && !articles.length && <i className="material-icons spinner">autorenew</i>}

          {Boolean(articles.length) && 
            articles.map(article => (
              <div key={article.id} className="article" onClick={() => this.selectArticle(article.id)}>
                <div>{article.title}</div>
                <Switch
                  checked={article.publish}
                  className="switch"
                  onChange={() => this.togglePublish(article.id)}
                  color="primary"
                  name="Publish"
                  inputProps={{ 'aria-label': 'publish' }}
                />
              </div>
            ))
          }
        </div>
      </div>
    );
  }

  selectArticle = (articleId) => {
    const { articles } = this.state;
    const selectedArticle = articles.find(item => item.id === articleId);
    const parsedContent = selectedArticle.content.reduce((a, b) => a + '\n' + b.content, '');
console.log('selectedArticle', parsedContent)
    if (selectedArticle) this.setState({ articleValues: { ...selectedArticle, content: parsedContent } });
  }

  togglePublish = async (articleId) => {
    const { articles } = this.state;
    const selectedArticle = articles.find(item => item.id === articleId);
    const isPublishing = !selectedArticle.publish;

    await this.db.collection('articles').doc(articleId).update({
      publish: isPublishing
    })
    .then(() => {
      this.getArticles();
    });
  }

  getArticles = async () => {
    this.setState({ isLoadingArticles: true });

    const articles = await this.db.collection('articles').get()
    .then(res => res.docs.map(item => item.data()));

    this.setState({ articles, isLoadingArticles: false });
  }

  handleChange = (event, target) => {
    const { articleValues } = this.state;

    articleValues[target] = event.target.value;

    this.setState({ articleValues });
  }

  submitArticle = () => {
    const { articleValues } = this.state;
    const timeStamp = firebase.firestore.FieldValue.serverTimestamp();

    this.setState({ pendingArticleUpdate: true });

    const contentArray = this.getContentArray(articleValues.content);

    this.db.collection('articles').doc(articleValues.id).set({ ...articleValues, content: contentArray, timeStamp })
    .then(() => {
      this.setState({ pendingArticleUpdate: false }, () => this.clearValues());
    });
  }

  getContentArray = content => {
    const breakContent = content.replace( /\n/g, ' [newline] ' )
    const arr = breakContent.split(' ');
    let paragraph = '';
    let pCount = 0;
    let pStyle = '';

    for (let i = arr.length - 1; i >= 0; i--) {
      const str = arr[i];
      const newObj = {}

      if (str.includes('[youtube')) {
        newObj.type = 'element'
        newObj.element = 'iframe';
        newObj.attrs = {
          allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
          allowfullscreen: true,
          src: str.substr(str.indexOf(':'))
        }

        if (typeof arr[i + 1] === 'string') {
          arr.splice(i + 1, pCount, {
            type: 'paragraph',
            content: paragraph
          });

          paragraph = '';
          pCount = 0;
          pStyle = '';
        }
      } else if (str === '[newline]') {
        newObj.type = 'newline';

        if (typeof arr[i + 1] === 'string') {
          const pObject = {
            type: 'paragraph',
            content: paragraph
          };

          if (pStyle.length) pObject.style = pStyle;

          arr.splice(i + 1, pCount, pObject);

          paragraph = '';
          pCount = 0;
          pStyle = '';
        }
      } else if (typeof str === 'string') {
        paragraph = str + ' ' + paragraph;
        pCount++;

        if (str.includes('*')) pStyle = 'bold';


        if (i === 0) {
          const pObject = {
            type: 'paragraph',
            content: paragraph
          };

          if (pStyle.length) pObject.style = pStyle;

          arr.splice(0, pCount, pObject);
        }
      }

      if (!paragraph.length) arr.splice(i, 1, newObj);
    }

    return arr.filter(obj => obj.type !== 'newline');
  }

  clearValues = () => {
    this.setState({
      articleValues: {
        author: '',
        content: [],
        title: '',
        titleES: ''
      }
    });
  }
}
