import React, { Component } from 'react';

import './styles.scss';

export default class Updater extends Component {
  render () {
    const { className, fields, id, onSubmit, submitDisabled, submitClass, submitContent } = this.props;

    return (
      <section key={id} className={className}>
        {fields.map(field => {
          const { element, id: fId, props } = field;
          const newElement = React.createElement(element, props);

          return (
            <React.Fragment key={fId}>
              {newElement}
            </React.Fragment>
          )
        })}

        <button className={`cta ${submitClass}`} disabled={submitDisabled} onClick={onSubmit}>{submitContent}</button>
      </section>
    )
  }
}
