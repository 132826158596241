import React, { useCallback, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

import { Button, MenuItem, Select, TextField } from '@material-ui/core';

import Updater from '../../../components/Console/components/Updater/index';

import './console.scss';

export default function Console({ athletes: propsAthletes }) {
  const firebaseConfig = {
    apiKey: "AIzaSyC7s4Z8I-Gsm3VGK2mjXA3v3gBnue3-hP8",
    authDomain: "crew-challenge.firebaseapp.com",
    databaseURL: "https://crew-challenge.firebaseio.com",
    projectId: "crew-challenge",
    storageBucket: "crew-challenge.appspot.com",
    messagingSenderId: "679290514764",
    appId: "1:679290514764:web:1ebf27c9d354cff0fc33e9",
    measurementId: "G-E7PPMWLX9Q"
  }
  
  let firebaseApp = firebase.apps && firebase.apps.find(app => app.name === 'crew-challenge-console');
  
  if (!firebaseApp) firebaseApp = firebase.initializeApp(firebaseConfig, 'crew-challenge-console');
  
  const db = firebaseApp.firestore();
  
  const activityOptions = ['bicycle', 'hike', 'ride', 'run', 'swim', 'walk'];
  const parsedPropsAthletes = propsAthletes && propsAthletes.map(({ userId, profile }) => ({ activities: [], userId, ...profile }));

  const [athletes, setAthletes] = useState(parsedPropsAthletes);
  const [activities, setActivities] = useState();
  const [isCreatingActivity, setIsCreatingActivity] = useState(false);
  const [newActivityDistance, setNewActivityDistance] = useState('');
  const [newActivityTime, setNewActivityTime] = useState('');
  const [newActivityType, setNewActivityType] = useState('walk');
  const [selectedAthlete, setSelectedAthlete] = useState();

  const submitNewActivity = async () => {
    setIsCreatingActivity(true);

    const timeISO = moment.utc(newActivityTime, 'YYYY-MM-DD HH:mm:ss').toISOString();

    const newActivity = {
      distance: newActivityDistance,
      exerciseType: newActivityType,
      startDate: timeISO,
    };
    const updatedActivities = [ ...selectedAthlete.activities, newActivity ];

    await db.collection('activities-hardcoded').doc(selectedAthlete.userId).set({
      activities: updatedActivities,
    })
    .then(() => setIsCreatingActivity(false))
    .catch(error => console.error('Unable to add activity.', error));
  }

  const updateSelectedAthlete = athleteId => {
    const selectedAthleteTemp = athletes.find(athlete => athlete.userId === athleteId);
    setSelectedAthlete(selectedAthleteTemp)
  }

  const fetchActivities = useCallback(async () => {
    const activitiesTemp = await db.collection('activities-hardcoded').get()
    .then(activitiesRes => activitiesRes.docs.map(item => item.data()));

    setActivities(activitiesTemp);

    const athletesTemp = athletes.map(athlete => {
      const athleteTemp = activitiesTemp.find(item => item.userId === athlete.userId);

      return ({
        ...athlete,
        activities: athleteTemp ? athleteTemp.activities : [],
      })
    });

    setAthletes(athletesTemp);
  }, [athletes, db]);

  const updateScoreboard = async () => {
    const currentActivities = await db.collection('activities').get().then(response => response.docs.map(item => item.data()));

    currentActivities.forEach(athlete => {    
      const thisMonth = [];
      const prevMonth = [];

      athlete.activities.forEach(item => {
        if (
          moment(item.startDate).isAfter(moment().subtract(1, 'month').startOf('month'))
          && moment(item.startDate).isBefore(moment().startOf('month'))
        ) {
          prevMonth.push(item);
        } else {
          thisMonth.push(item);
        }
      });

      const thisMonthTotal = thisMonth.reduce((a, b) => {
        return a + +b.distance;
      }, 0);

      const prevMonthTotal = prevMonth.reduce((a, b) => {
        return a + +b.distance;
      }, 0);

      db.collection('athleteDistance').doc(athlete.userId).set({
        totalDistance: thisMonthTotal
      }, {
        merge: true,
      });

      db.collection('athleteDistancePrev').doc(athlete.userId).set({
        totalPrevDistance: prevMonthTotal,
        userId: athlete.userId,
      }, {
        merge: true,
      });

      db.collection('activities').doc(athlete.userId).set({
        activities: thisMonth
      }, {
        merge: true
      });

      if (athlete.userId !== '31626327' && athlete.userId !== '58075240') {
        db.collection('activitiesPrev').doc(athlete.userId).set({
          activities: prevMonth,
          userId: athlete.userId,
        }, {
          merge: true
        });
      }
    });

    
  }

  useEffect(() => {
    if (propsAthletes && athletes && !activities) fetchActivities();
  }, [activities, fetchActivities, propsAthletes, athletes]);

  useEffect(() => {
    if (propsAthletes && !athletes) setAthletes(parsedPropsAthletes);
  }, [athletes, parsedPropsAthletes, propsAthletes]);

  return (
    <div className="console">
      <Updater
        id="add-activity"
        className="add-activity"
        fields={[
          {
            id: 'activity-type',
            element: Select,
            props: {
              className: 'input',
              children: activityOptions.map(obj => (
                <MenuItem key={obj} value={obj}>{obj}</MenuItem>
              )),
              value: newActivityType,
              onChange: event => setNewActivityType(event.target.value),
              inputProps: {
                name: 'selected-type',
                id: 'selected-type'
              },
            }
          },
          {
            id: 'activity-distance',
            element: TextField,
            props: {
              className: 'input',
              label: 'Distance',
              value: newActivityDistance,
              onChange: event => setNewActivityDistance(event.target.value)
            }
          },
          {
            id: 'activity-athlete',
            element: Select,
            props: {
              className: 'input',
              children: athletes && athletes.map(obj => (
                <MenuItem key={obj.userId} value={obj.userId}>{obj.firstName} {obj.lastName}</MenuItem>
              )),
              value: selectedAthlete && selectedAthlete.userId,
              onChange: event => updateSelectedAthlete(event.target.value),
              inputProps: {
                name: 'selected-athlete',
                id: 'selected-athlete'
              },
            }
          },
          {
            id: 'activity-time',
            element: TextField,
            props: {
              className: 'input',
              label: 'Time',
              value: newActivityTime,
              onChange: event => setNewActivityTime(event.target.value)
            }
          },
        ]}
        submitDisabled={!newActivityTime || !newActivityDistance}
        onSubmit={submitNewActivity}
        submitClass="submit-activity"
        submitContent={<>Submit New Activity {isCreatingActivity && <i className="material-icons spinner">autorenew</i>}</>}
      />

      <Button onClick={updateScoreboard}>
        Update scoreboard
      </Button>
    </div>
  );
}