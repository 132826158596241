import React, { useEffect, useState } from 'react';

import NFTContainer from './NFTContainer';

import "../styles.scss";

const ResultsTable = ({ isImmersive, isLoadingNext, loadNext, openMenu, results }) => {
  const skeletons = [];

  // Try to keep the same six cards
  for (let i = 0; i < 6; i++) {
    skeletons.push({
      id: { raw: `skeleton-${i}` },
      isSkeleton: true,
    });
  }

  const [cards, setCards] = useState([...skeletons]);

  useEffect(() => {
    console.log('ue results')
    console.log(results, isLoadingNext, skeletons, cards)
    if (results && !isLoadingNext && cards[0].isSkeleton) {
      const firstSix = results.slice(0, 6);

      setCards([...firstSix]);
    }
  }, [results, isLoadingNext, setCards, skeletons, cards]);

  return (
    <div className="results-table">
      {cards.map(nft => <NFTContainer {...nft} key={nft.id.raw} openMenu={openMenu} isImmersive={isImmersive} />)}
    </div>
  )
};

export default ResultsTable;