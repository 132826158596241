import React, { Component } from 'react';

import { get } from 'lodash';

import classnames from 'classnames';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import Updater from '../components/Updater'

import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

import { firebaseConfig } from '../constants.js';

import './styles.scss';

export default class Challenges extends Component {
  firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  db = this.firebaseApp.firestore();
  state = { creatorValues: {}, landmarkValues: {} };

  componentDidUpdate(prevProps, prevState) {
    const { challenges, creatorValues } = this.state;

    if (creatorValues.type && !challenges) this.getChallenges();
  }

  render () {
    const { missions } = this.props;
    const {
      challenges,
      creatorValues,
      errorMessage,
      landmarkValues,
      pendingChallengeUpdate,
      pendingLandmarkUpdate,
      pendingUpdateChallenge,
      selectedChallenge
    } = this.state;

    const isLandmarkSubmittable = landmarkValues.name && landmarkValues.nameES && landmarkValues.imageUrl && landmarkValues.imageUrlSmall;

    return (
      <>
        <div className="challenges-container">
          {Boolean(errorMessage?.length) && 
            <div className="banner error">{errorMessage}</div>
          }

          <Updater
            id="challenge-creater"
            className="challenge-creater"
            fields={[
              {
                id: 'mission-select',
                element: Select,
                props: {
                  value: creatorValues.type,
                  inputProps: {
                    name: 'selected-mission',
                    id: 'selected-mission'
                  },
                  onChange: event => this.handleCreatorChange(event, 'type'),
                  className: 'input mission',
                  children: missions && missions.map(obj => (
                    <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>
                  ))
                }
              },
              {
                id: 'prestige',
                element: FormControlLabel,
                props: {
                  control: (
                    <Checkbox
                      checked={Boolean(creatorValues.prestige)}
                      onChange={event => this.handleCreatorChange(event, 'prestige')}
                      value={creatorValues.prestige}
                    />
                  ),
                  className: 'prestige',
                  label: 'Prestige'
                }
              },
              {
                id: 'challenge-label',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Label',
                  value: creatorValues.label,
                  onChange: event => this.handleCreatorChange(event, 'label')
                }
              },
              {
                id: 'challenge-label-es',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Label (Spanish)',
                  value: creatorValues.labelES,
                  onChange: event => this.handleCreatorChange(event, 'labelES')
                }
              },
              {
                id: 'challenge-description',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Description',
                  value: creatorValues.description || '',
                  onChange: event => this.handleCreatorChange(event, 'description')
                }
              },
              {
                id: 'challenge-description-es',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Description (Spanish)',
                  value: creatorValues.descriptionES || '',
                  onChange: event => this.handleCreatorChange(event, 'descriptionES')
                }
              },
              {
                id: 'challenge-posX',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Position X',
                  value: get(creatorValues, 'position.x', ''),
                  onChange: event => this.handleCreatorChange(event, 'positionX')
                }
              },
              {
                id: 'challenge-posY',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Position Y',
                  value: get(creatorValues, 'position.x', ''),
                  onChange: event => this.handleCreatorChange(event, 'positionY')
                }
              },
              {
                id: 'challenge-parentId',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Parent Id',
                  value: creatorValues.parentId,
                  onChange: event => this.handleCreatorChange(event, 'parentId')
                }
              },
              {
                id: 'challenge-landmark_id',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Landmark Id',
                  value: creatorValues.landmark_id,
                  onChange: event => this.handleCreatorChange(event, 'landmark_id')
                }
              },
              {
                id: 'challenge-img',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Image Url',
                  value: creatorValues.imageUrl,
                  onChange: event => this.handleCreatorChange(event, 'imageUrl')
                }
              },
              {
                id: 'challenge-imgSmall',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Image Url (Small)',
                  value: creatorValues.imageUrlSmall,
                  onChange: event => this.handleCreatorChange(event, 'imageUrlSmall')
                }
              }
            ]}
            submitDisabled={!creatorValues.type || !creatorValues.label || !creatorValues.labelES}
            onSubmit={this.submit}
            submitClass="submit-challenge"
            submitContent={<>Submit {pendingChallengeUpdate && <i className="material-icons spinner">autorenew</i>}</>}
          />

          <div className="challenge-buttons">
            <button className="cta" onClick={this.nextChallenge}>+ Challenge</button>
            <button className="cta" onClick={this.siblingChallenge}>+ Sibling</button>
          </div>

          {Boolean(creatorValues.type?.length) &&
            <div className="challenges-list">
              <h4>Challenges List</h4>

              {challenges?.length
                ? <>
                    {challenges.map(challenge => (
                      <div key={challenge.challengeId} onClick={() => this.setState({ selectedChallenge: challenge })} className={classnames('challenge', {
                        'selected': challenge.challengeId === selectedChallenge?.challengeId
                      })}>
                        {challenge.challengeId === selectedChallenge?.challengeId 
                          ? <>
                              <p>Label:</p><input type="text" value={selectedChallenge.label} className="edit-name" onChange={event => this.setState({ selectedChallenge: {...selectedChallenge, label: event.target.value} })} />
                              <p>LabelES:</p><input type="text" value={selectedChallenge.labelES} onChange={event => this.setState({ selectedChallenge: { ...selectedChallenge, labelES: event.target.value } })} />
                            
                              <Button onClick={this.updateChallenge}>{pendingUpdateChallenge ? <i className="material-icons spinner">autorenew</i> : 'Submit'}</Button>
                            </>
                          : <div className="mission-name">{challenge.label}</div>
                        }
                      </div>
                    ))}
                  </>
                : 'No matching challenges found.'
              }
            </div>
          }
        </div>
        <div className="landmarks-container">
          <Updater
            id="landmark-creater"
            className="landmark-creater"
            fields={[
              {
                id: 'landmark-name',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Name',
                  value: landmarkValues.name || '',
                  onChange: event => this.handleLandmarkChange(event, 'name')
                }
              },
              {
                id: 'landmark-name-es',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Name (Spanish)',
                  value: landmarkValues.nameES || '',
                  onChange: event => this.handleLandmarkChange(event, 'nameES')
                }
              },
              {
                id: 'landmark-description',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Description',
                  value: landmarkValues.description || '',
                  onChange: event => this.handleLandmarkChange(event, 'description')
                }
              },
              {
                id: 'landmark-description-es',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Description (Spanish)',
                  value: landmarkValues.descriptionES || '',
                  onChange: event => this.handleLandmarkChange(event, 'descriptionES')
                }
              },
              {
                id: 'landmark-posX',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Position X',
                  value: get(landmarkValues, 'position.x', ''),
                  onChange: event => this.handleLandmarkChange(event, 'positionX')
                }
              },
              {
                id: 'landmark-posY',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Position Y',
                  value: get(landmarkValues, 'position.y', ''),
                  onChange: event => this.handleLandmarkChange(event, 'positionY')
                }
              },
              {
                id: 'landmark-img',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Image Url',
                  value: landmarkValues.imageUrl || '',
                  onChange: event => this.handleLandmarkChange(event, 'imageUrl')
                }
              },
              {
                id: 'landmark-imgSmall',
                element: TextField,
                props: {
                  className: 'input',
                  label: 'Image Url (Small)',
                  value: landmarkValues.imageUrlSmall || '',
                  onChange: event => this.handleLandmarkChange(event, 'imageUrlSmall')
                }
              }
            ]}
            submitDisabled={!isLandmarkSubmittable}
            onSubmit={this.submitLandmark}
            submitClass="submit-landmark"
            submitContent={<>Submit Landmark {pendingLandmarkUpdate && <i className="material-icons spinner">autorenew</i>}</>}
          />
        </div>
      </>
    )
  }

  clearValues = () => {
    const { creatorValues, landmarkValues } = this.state;

    Object.keys(creatorValues).forEach(key => {
      creatorValues[key] = '';
    });

    Object.keys(landmarkValues).forEach(key => {
      landmarkValues[key] = '';
    });

    this.setState({creatorValues, landmarkValues});
  }

  handleLandmarkChange = (event, target) => {
    const { landmarkValues } = this.state;

    if (target === 'positionX') {
      if (!landmarkValues.position) landmarkValues.position = { x: +event.target.value };
      else landmarkValues.position.x = +event.target.value;
    }
    else if (target === 'positionY') {
      if (!landmarkValues.position) landmarkValues.position = { y: +event.target.value };
      else landmarkValues.position.y = +event.target.value;
    }
    else landmarkValues[target] = event.target.value;

    this.setState({ landmarkValues });
  }

  getChallenges = () => {
    const { creatorValues: { type } } = this.state;

    if (!type) return this.setState({ challenges: [] });

    this.db.collection('challenges').where('type', '==', type).get()
    .then(challengesData => {
      const challenges = challengesData.docs.map(rawChallenge => rawChallenge.data());

      challenges.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);

      this.setState({ challenges });
    });
  }

  handleCreatorChange = (event, target) => {
    const { creatorValues } = this.state;

    if (target === 'prestige') creatorValues.prestige = !creatorValues.prestige;
    else if (target === 'positionX') {
      if (!creatorValues.position) creatorValues.position = { x: +event.target.value };
      else creatorValues.position.x = +event.target.value;
    }
    else if (target === 'positionY') {
      if (!creatorValues.position) creatorValues.position = { y: +event.target.value };
      else creatorValues.position.y = +event.target.value;
    }
    else creatorValues[target] = event.target.value;

    this.setState({ creatorValues });
  }

  nextChallenge = () => {
    const { creatorValues } = this.state;

    this.setState({
      nextChallenge: {
        type: creatorValues.type
      },
      pendingNext: true
    });

    this.submit().then(() => {
      this.setState({ creatorValues: { ...this.state.creatorValues, ...this.state.nextChallenge }, pendingNext: false });
    });
  }

  siblingChallenge = () => {
    const { creatorValues } = this.state;

    this.setState({
      nextChallenge: {
        parentId: creatorValues.parentId,
        landmark_id: creatorValues.landmark_id,
        prestige: creatorValues.prestige,
        label: creatorValues.label,
        labelES: creatorValues.labelES,
        type: creatorValues.type
      },
      pendingSibling: true
    });

    this.submit().then(() => {
      this.setState({ creatorValues: { ...this.state.creatorValues, ...this.state.nextChallenge }, pendingSibling: false });
    });
  }

  submitLandmark = async () => {
    const { landmarkValues } = this.state;
    const newLandmark = { ...landmarkValues, active: true };

    this.setState({ pendingLandmarkUpdate: true });

    const newDocRef = await this.db.collection('landmarks').doc();

    newLandmark.id = newDocRef.id;

    return newDocRef.set(newLandmark)
    .then(() => {
      this.setState({ pendingLandmarkUpdate: false }, () => this.clearValues());
    });
  }

  updateChallenge = () => {
    const { selectedChallenge } = this.state;

    this.setState({ errorMessage: '', pendingUpdateChallenge: true });

    this.db.collection('challenges').doc(selectedChallenge.challengeId).update({
      ...selectedChallenge
    }).then(() => {
      this.setState({ selectedChallenge: undefined, pendingUpdateChallenge: false }, () => {
        this.getChallenges();
      });
    })
    .catch(err => {
      this.setState({ errorMessage: err, pendingUpdateChallenge: false })
    });
  }

  submit = async () => {
    const { creatorValues } = this.state;
    const newChallenge = {};
    const positionX = get(creatorValues, 'position.x', '');
    const positionY = get(creatorValues, 'position.y', '');

    this.setState({ pendingChallengeUpdate: true });

    if (positionX.trim().length && positionY.trim().length) {
      newChallenge.mapped = true;
      newChallenge.position = { x: +positionX.trim(), y: +positionY.trim() };
    }

    if (creatorValues.prestige) newChallenge.isPrestige = true;
    if (get(creatorValues, 'parentId.length')) newChallenge.parentId = creatorValues.parentId;
    if (get(creatorValues, 'landmark_id.length')) newChallenge.landmark_id = creatorValues.landmark_id;
    if (creatorValues.imageUrl && creatorValues.imageUrl.length) newChallenge.screenshot_source = creatorValues.imageUrl;
    if (creatorValues.imageUrlSmall && creatorValues.imageUrlSmall.length) newChallenge.screenshot_source_small = creatorValues.imageUrlSmall;

    if (creatorValues.description) newChallenge.description = creatorValues.description;
    if (creatorValues.descriptionES) newChallenge.descriptionES = creatorValues.descriptionES;
    newChallenge.labelES = creatorValues.labelES;
    newChallenge.label = creatorValues.label;
    newChallenge.type = creatorValues.type;
    newChallenge.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    newChallenge.season = 11;

    const newDocRef = await this.db.collection('challenges').doc();

    newChallenge.challengeId = newDocRef.id;

    return newDocRef.set(newChallenge)
    .then(() => {
      this.setState({ pendingChallengeUpdate: false }, () => this.clearValues());
    });
  }
}
