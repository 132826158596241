import React, { useEffect, useState } from 'react';

import classnames from "classnames";

import { getPercentChange } from "../utils";

import "../styles.scss";

const Item = ({
  averagePrice,
  contractAddress,
  streakCreator = 0,
  description,
  fromTitle,
  fromUser,
  href,
  isAgainstTrend,
  isExpanded,
  isSteal,
  isWatched,
  itemId,
  itemImageSrc,
  itemName,
  milliseconds,
  niftyType,
  price,
  streakItem = 0,
  toTitle,
  toggleItemCollapsed,
  toggleItemWatched,
  toUser,
  transactionClassName,
  transactionIcon,
  transactionLabel,
  unfolded,
}) => {
  const hasStreak = streakItem || streakCreator || fromUser?.streakSeller || fromUser?.streakBidder || toUser?.streakBuyer;

  const [averagePriceChange, setAveragePriceChange] = useState();
  const [hasImgError, setHasImgError] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [timePassed, setTimePassed] = useState();
  const [img, setImg] = useState();
  const [isVideo, setIsVideo] = useState(itemImageSrc?.endsWith('.mp4'));
  const [imageUrl, setImageUrl] = useState();

  const toggleCollapsed = (event) => {
    event.stopPropagation();
    toggleItemCollapsed(itemId);
  }

  const toggleWatched = (event) => {
    event.stopPropagation();
    toggleItemWatched(contractAddress, itemName, niftyType, !isWatched);
  }

  useEffect(() => {
    const tempIsVideo = itemImageSrc?.endsWith('.mp4');

    setIsVideo(tempIsVideo);

    const splitIndex = itemImageSrc?.indexOf('/upload')
    let tempImageUrl = itemImageSrc?.split('');
    const addString = tempIsVideo ? '/vc_auto,w_200' : '/q_jpegmini,w_200';

    if (tempImageUrl) {
      tempImageUrl.splice(splitIndex + 7, 0, addString);
      tempImageUrl = tempImageUrl.join('');
    }

    const tempImg = new Image();
    tempImg.onload = () => setIsImgLoaded(true);
    tempImg.src = tempImageUrl;

    setImageUrl(tempImageUrl);
    setImg(tempImg);
  }, [itemImageSrc]);

  // Set the time since
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const nowMilliseconds = now.getTime();

      const passedMilliseconds = nowMilliseconds - milliseconds;
      const minutes = Math.floor(passedMilliseconds / 60000);
      const seconds = ((passedMilliseconds % 60000) / 1000).toFixed(0);
      
      setTimePassed(`${minutes}m ${(seconds < 10 ? '0' : '') + seconds}s ago`);
    }, 1000);

    return () => clearInterval(interval);
  }, [milliseconds]);

  // Get the item price change
  useEffect(() => {
    const updateData = () => {
      const tempChange = getPercentChange(price, averagePrice);

      setAveragePriceChange(tempChange);
    }

    if (averagePrice && !averagePriceChange) updateData();
  }, [averagePrice, averagePriceChange, price]);

  return (
    <div
      className={classnames('listing', transactionClassName, {
        collapsed: !isExpanded,
        streak: hasStreak,
      })}
      onClick={(e) => !isExpanded && toggleCollapsed(e)}
    >
      <div className={classnames('label', transactionClassName)}>
        <i className="material-icons">{transactionIcon}</i>
        <span>{transactionLabel}</span>
        <i className="material-icons watchlist-button" onClick={(e) => toggleWatched(e)}>{isWatched ? 'star' : 'star_border'}</i>
        {unfolded && <i className="material-icons collapse-button" onClick={(e) => toggleCollapsed(e)}>unfold_less</i>}
      </div>
      <div className="time-passed">{timePassed}</div>
        {isExpanded && (
          <div className="from">
            {fromUser && (
                <>
                  <p>{fromTitle}</p>
                  <div className="avatar-container">
                    <img src={fromUser.avatarSrc} alt={fromUser.name} />
                  </div>
                  <span>{fromUser.name}</span>
                </>
            )}
          </div>
        )}
        <div className="info-box">
          <div className="item-name">{itemName}</div>
            {isExpanded && (
              <>
                {isVideo
                ? (
                  <video autoPlay loop muted className="show">
                      <source src={imageUrl} type='video/mp4' />
                  </video>
                )
                : isImgLoaded && !hasImgError
                  ? <img className={classnames({ show: isImgLoaded })} src={img.src} alt={description} onError={() => setHasImgError(true)} />
                  : null
              }
              </>
            )}
          {["lightblue", "blue", "green"].some(i => i === transactionClassName) && (
            <div className={classnames('price', {
              'anti-trend': isAgainstTrend,
            })}>
              {isSteal
                ? (
                  <div className="steal">
                    <span className="shiny">
                      <span className="inner-shiny">{price.toLocaleString("en-US", {style:"currency", currency:"USD"})}</span>
                    </span>
                    <span className="steal-text">{"Item 50% lower than avg resale price, or 30% lower and cheaper than $150"}</span>
                  </div>
                )
                : <span className="amount">{price.toLocaleString("en-US", {style:"currency", currency:"USD"})}</span>
              }
              
              {Boolean(averagePriceChange)
                ? <span className={classnames('price-change', {
                  red: averagePriceChange < 0,
                  green: averagePriceChange > 0,
                })}>
                  <span className="indicator material-icons">play_arrow</span>
                  <span className="average-change">({averagePriceChange.toFixed(2)}%)</span>
                  <span className="average">Average resale price: {averagePrice ? averagePrice.toLocaleString("en-US", {style:"currency", currency:"USD"}) : 'N/A'}</span>
                </span>
                : (
                  <span className="price-change">
                    (-)
                    <span className="average">Same as average resale price</span>
                  </span>
                )
              }
            </div>
          )}
        </div>
        {toUser && isExpanded && (
          <div className="to">
            <p>{toTitle}</p>
            <div className="avatar-container">
              <img src={toUser.avatarSrc} alt={toUser.name} />
            </div>
            <span>{toUser.name}</span>
          </div>
        )}
        {isExpanded && <a className="link" href={href} target="_blank" rel="noreferrer noopener">View on Nifty Gateway</a>}
        {hasStreak && (
          <div className="streak-container">
            <div className={classnames('streak from-user', {
              visible: fromUser && (fromUser.streakSeller || fromUser.streakBidder),
            })}>
              <span className="count">{fromUser?.streakSeller || fromUser?.streakBidder}</span>
              <div className="message"><span>{fromUser?.name}</span>{`is on a ${fromUser?.streakSeller ? transactionClassName === 'blue' ? 'selling' : 'listing' : 'bidding'} streak`}</div>
            </div>

            <div className={classnames('streak item', {
                visible: streakItem || streakCreator,
              })}>
              <span className="count">{Math.max(streakItem, streakCreator)}</span>
              <div className="message"><span>{streakItem >= streakCreator ? itemName : 'This creator is on a'}</span>{`streak`}</div>
            </div>
            
            <div className={classnames('streak to-user',
              {
                visible: toUser?.streakBuyer,
              })}
            >
              <span className="count">{toUser?.streakBuyer}</span>
              <div className="message"><span>{toUser?.name}</span>{`is on a buying streak`}</div>
            </div>
          </div>
        )}
    </div>
  )
};

export default Item;