import React from 'react';

import classnames from 'classnames';

import Switch from '@material-ui/core/Switch';

import './styles.scss';

const Filter = ({
  onRemove,
  isOn,
  displayName,
  onUpdate,
}) => {
  return (
    <div className="filter">
      <button className="material-icons" onClick={onRemove}>close</button>
      <span className={classnames({ fade: !isOn })}>{isOn ? `${displayName} is included` : `${displayName} won't show`}</span>
      <Switch
        checked={isOn}
        className={classnames('switch', {
          checked: isOn,
        })}
        onChange={onUpdate}
        color="primary"
        name="Publish"
        inputProps={{ 'aria-label': 'publish' }}
      />
    </div>
  );
}

export default Filter;