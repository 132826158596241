import "@babel/polyfill";
import 'core-js';
import 'raf/polyfill';

import React from "react";

import { BrowserRouter as Router, Switch, Link, Route } from "react-router-dom";

import Console from './components/Console';
import CrewChallenge from './views/CrewChallenge';
import { FortniteDashboard } from './views/FortniteDashboard';
import NFTAggregator from './views/NFTAggregator';
import Activity from './views/Activity';
import TestPage from './views/TestPage';

import Button from '@material-ui/core/Button';
import './AppRouter.scss';

function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/activity" component={Activity} />
          <Route exact path="/nft-aggregator" component={NFTAggregator} />
          <Route exact path="/test-page" component={TestPage} />
          <Route exact path="/crew-challenge" component={CrewChallenge} />
          <Route path="/fortnite-dashboard" component={FortniteDashboard} />
          <Route path="/fortbyte-map" component={FortniteDashboard} />
          <Route path="/yeet-console" component={Console} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  );
}

function PageNotFound() {
  return (
    <div className="home">
      <p>Page not found</p>
    </div>
  );
}

function Home() {
  return (
    <div className="home">
      <p>Welcome!</p>

      <p>Ongoing projects</p>

      <div className="buttons">
        {/* <Link to={'/nft-aggregator'}>
          <Button className="go-to-map">Non-fungible Token Aggregator</Button>
        </Link> */}
        <Link to={'/crew-challenge'}>
          <Button className="go-to-map">Crew Challenge</Button>
        </Link>
      </div>

      <p>Writeups on my previous projects</p>

      <div className="buttons">
        <Link to={'/fortbyte-map'}>
          <Button className="go-to-map">Fortbyte Map</Button>
        </Link>
      </div>
    </div>
  );
}

export default AppRouter;
