import React from 'react';

import classnames from 'classnames';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Filter from './Filter';

const AllSettings = ({
  feedSettings,
  hasSelecteNFT,
  hide,
  removeFeedFilter,
  settings,
  updateFeedFilter,
  updateSettings,
}) => {
  return (
    <div className={classnames('all-settings-container', {
      hide,
      wide: hasSelecteNFT,
    })}>
      <div className="settings">
        <div className="setting">
          <h4>View</h4>
          <RadioGroup className="controls" aria-label="View" name="view" value={settings.isImmersive} onChange={() => updateSettings('isImmersive', !settings.isImmersive)}>
            <FormControlLabel value={true} control={<Radio />} label="Immersive" />
            <FormControlLabel value={false} control={<Radio />} label="Detailed" />
          </RadioGroup>
        </div>
      </div>
      {Boolean([...feedSettings.creator, ...feedSettings.series, ...feedSettings.source].length) && (
        <div className="custom-filters">
          <header>
            <h3>Custom Filters</h3>
          </header>

          {Boolean(feedSettings.creator.length) && <h4>Creator</h4>}
          {feedSettings.creator.map(item => (
            <Filter
              onRemove={() => removeFeedFilter('creator', item.label)}
              isOn={item.value}
              displayName={item.label}
              onUpdate={() => updateFeedFilter('creator', item.label, !item.value)}
            />
          ))}

          {Boolean(feedSettings.series.length) && <h4>Series</h4>}
          {feedSettings.series.map(item => (
            <Filter
              onRemove={() => removeFeedFilter('creator', item.label)}
              isOn={item.value}
              displayName={item.label}
              onUpdate={() => updateFeedFilter('creator', item.label, !item.value)}
            />
          ))}

          {Boolean(feedSettings.source.length) && <h4>Source</h4>}
          {feedSettings.source.map(item => (
            <Filter
              onRemove={() => removeFeedFilter('creator', item.label)}
              isOn={item.value}
              displayName={item.label}
              onUpdate={() => updateFeedFilter('creator', item.label, !item.value)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default AllSettings;