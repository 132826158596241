import React, { useCallback, useEffect, useState } from 'react';

import classnames from 'classnames';

const WatchlistItem = ({ ca, getItemPrices, highestOffer, itemName, lastSale, marketLow, niftyType, removeItemWatched}) => {
  const [highest, setHighest] = useState(highestOffer);
  const [low, setLow] = useState(marketLow);
  const [last, setLast] = useState(lastSale);
  const [isLoading, setIsLoading] = useState(false);

  const retrieveItem = useCallback(async () => {
    const data = await getItemPrices({ ca, niftyType });

    if (data) {
      const { highestOffer: tempHighestOffer, marketLow: tempMarketLow, lastSale: tempLastSale } = data;

      setHighest(tempHighestOffer);
      setLow(tempMarketLow);
      setLast(tempLastSale);
    }
  }, [getItemPrices, ca, niftyType]);

  useEffect(() => {
    if (!(highest && last && low) && !isLoading) {
      setIsLoading(true);
      retrieveItem();
    }
  }, [highest, last, low, getItemPrices, isLoading, retrieveItem]);

  useEffect(() => {
    if (highest && last && low && isLoading) setIsLoading(false);
  }, [highest, last, low, isLoading]);

  useEffect(() => {
    if (!(highestOffer && marketLow && lastSale) && (highest && low && lastSale)) retrieveItem();
  }, [highestOffer, marketLow, lastSale, highest, low, last, retrieveItem]);

  return (
    <tr>
      <td className="name">{itemName}</td>
      <td className="no-mobile">{highest ? (highest / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 'Loading'}</td>
      <td>{low ? (low / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 'Loading'}</td>
      <td>{last ? (last / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : 'Loading'}</td>
      <td className="remove">
        <button className='material-icons' onClick={removeItemWatched}>close</button>
      </td>
    </tr>
  );
};

const OfferWatch = ({
  getItemPrices,
  items,
  noMobile,
  refreshAll,
  resetShouldReloadWatchlist,
  shouldReloadWatchlist,
  toggleItemWatched,
  watchList,
}) => {

  useEffect(() => {
    if (shouldReloadWatchlist) resetShouldReloadWatchlist();
  }, [resetShouldReloadWatchlist, shouldReloadWatchlist]);

  return (
    <div className={classnames('offer-watch', {
      'no-mobile': noMobile,
    })}>
      <header>
        <h2>Watch List</h2>

        <button onClick={refreshAll} className={classnames('refresh', {
          loading: shouldReloadWatchlist,
        })}>
          <i className="material-icons">refresh</i>
        </button>
      </header>
      <p>Click the star on any item to add it to your watch list.</p>

      {Boolean(!shouldReloadWatchlist && watchList?.length) &&
        <table>
          <thead>
            <tr>
              <th className="name">Name</th>
              <th className='value highest no-mobile'>Highest bid</th>
              <th className='value lowest'>Lowest listing</th>
              <th className='value last'>Last sale</th>
              <th className="remove"></th>
            </tr>
          </thead>
          
          <tbody>
            {watchList.map(({ itemName, ca, niftyType }, i) => {
              const isNumbered = itemName.indexOf('#') >= 0;
              const truncItemName = isNumbered ? itemName.substring(0, itemName.indexOf('#') - 1) : itemName;

              return(
                <WatchlistItem
                  ca={ca}
                  key={`${itemName}-${i}`}
                  items={items}
                  itemName={truncItemName}
                  getItemPrices={() => getItemPrices({ca, niftyType})}
                  removeItemWatched={() => toggleItemWatched(ca, truncItemName, niftyType, false)}
                  niftyType={niftyType}
                />
            )})}
          </tbody>
        </table>
      }
    </div>
  );
};

export default OfferWatch;