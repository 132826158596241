import React, { useCallback, useEffect, useState } from "react";

import classnames from "classnames";
import { compact } from 'lodash';

import Modal from '@material-ui/core/Modal';

import { notatedItems } from "./utils";

import Item from "./components/Item";
import OfferWatch from "./components/OfferWatch";

import "./styles.scss";

const initFilters = [
  {
    color: "blue",
    label: "Sale",
    icon: "gavel",
    isSelected: true,
  },
  {
    color: "lightblue",
    label: "Listing",
    icon: "storefront",
    isSelected: true,
  },
  {
    color: "green",
    label: "Offer/Bid",
    icon: "local_offer",
    isSelected: true,
  },
  {
    color: "pink",
    label: "Deposit / Mint",
    icon: "add_box",
    isSelected: true,
  },
  {
    color: "yellow",
    label: "Transfer",
    icon: "redeem",
    isSelected: true,
  },
  {
    color: "gray",
    label: "Withdrawal",
    icon: "eject",
    isSelected: true,
  }
];

const Activity = () => {
  const hydratedFilters = localStorage.getItem('filters');

  const localWatchStr = localStorage.getItem('watchList');
  const localWatch = localWatchStr ? JSON.parse(JSON.parse(localWatchStr)) : [];

  const [averages, setAverages] = useState({});
  const [filters, setFilters] = useState(hydratedFilters ? JSON.parse(hydratedFilters) : initFilters);
  const [gotAverages, setGotAverages] =  useState(false);
  const [gotNotated, setGotNotated] =  useState(false);
  const [hasItemsFetchStarted, setHasItemsFetchStarted] = useState(false);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [keepOpen, setKeepOpen] = useState([]);
  const [shouldReloadWatchlist, setShouldReloadWatchlist] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showWatchlistModal, setShowWatchlistModal] = useState(false);
  const [watchList, setWatchList] = useState(localWatch);

  const fetchItems = useCallback(() => {
    if (!isLoading) setIsLoading(true);
    setAverages({});

    const raw = { isTroll: "p9", page: 1, amount: 6 }
    const body = JSON.stringify(raw);
    let headers = new Headers();

    headers.append("Accept", "*/*");
    headers.append("Accept-Language", "en-US,en;q=0.5");
    headers.append("Content-Type", "text/plain");

    return fetch('https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/get-activity',
      {
        body,
        headers,
        method: 'POST',
        redirect: 'follow'
      })
      .then(res => {
        const text = res.text();
        return text;
      })
      .catch(e => console.log(`Error fetching results: ${e}`));
  }, [isLoading]);

  const fetchItemDetails = useCallback((cAddress, nType) => {
    const raw = { isTroll: "p9", contractAddress: cAddress, niftyType: nType };
    const body = JSON.stringify(raw);
    let headers = new Headers();

    headers.append("Accept", "*/*");
    headers.append("Accept-Language", "en-US,en;q=0.5");
    headers.append("Content-Type", "text/plain");

    return fetch(`https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/get-nft-data-ng`,
      {
        body,
        headers,
        method: 'POST',
        redirect: 'follow'
      })
      .then(res => {
        const text = res.text();
        return text;
      })
      .catch(e => console.log(`Error fetching results: ${e}`));
  }, []);
  
  const fetchItemMarketLow = useCallback((cAddress, nType) => {
    const raw = { isTroll: "p9", contractAddress: cAddress, niftyType: nType };
    const body = JSON.stringify(raw);
    let headers = new Headers();

    headers.append("Accept", "*/*");
    headers.append("Accept-Language", "en-US,en;q=0.5");
    headers.append("Content-Type", "text/plain");

    return fetch(`https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/get-nft-market-lows`,
      {
        body,
        headers,
        method: 'POST',
        redirect: 'follow'
      })
      .then(res => {
        const text = res.text();
        return text;
      })
      .catch(e => console.log(`Error fetching results: ${e}`));
  }, []);

  const itemGet = useCallback((isRefresh) => {
    const tempHighestOffers = {};

    if (hasItemsFetchStarted && !isRefresh) return null;

    if (isRefresh) {
      setAverages({})
      setGotAverages(false);
      setGotNotated(false);
    }

    try {
      fetchItems().then((res) => {
        if (res) {
          const response = JSON.parse(res);
          const tempItems = response;
          const promises = [];
          const tempAverages = {};

          setItems(tempItems.map(({
            Timestamp: timestamp,
            NiftyObject,
            BiddingUserProfile: biddingUser,
            BirthingUserProfile: birthingUser,
            ListingUserProfile: listingUser,
            OfferingUserProfile: offeringUser,
            SellingUserProfile: sellingUser,
            SendingUserProfile: sendingUser,
            PurchasingUserProfile: purchasingUser,
            ReceivingUserProfile: receivingUser,
            WithdrawingUserProfile: withdrawingUser,
            BidAmountInCents: bidAmountCents,
            ListingAmountInCents: listAmountCents,
            OfferAmountInCents: offerAmountCents,
            SaleAmountInCents: saleAmountCents,
            UnmintedNiftyObj,
          }) => {
            const { id: itemId, tokenId } = NiftyObject || UnmintedNiftyObj || {};
    
            const contractAddress = NiftyObject ? NiftyObject.contractAddress : UnmintedNiftyObj.niftyContractAddress;
            const itemImageSrc = NiftyObject ? NiftyObject.image_url : UnmintedNiftyObj.niftyImageURL;
            const itemName = NiftyObject ? NiftyObject.name : UnmintedNiftyObj.niftyTitle;
            const description = NiftyObject ? NiftyObject.description : UnmintedNiftyObj.niftyDescription;
            const niftyType = NiftyObject ? NiftyObject.unmintedNiftyObjThatCreatedThis.niftyType : UnmintedNiftyObj.niftyType;
            const creatorId = NiftyObject ? NiftyObject.unmintedNiftyObjThatCreatedThis.contractObj.userWhoCreated : UnmintedNiftyObj.contractObj.userWhoCreated;
    
            const amountCents = bidAmountCents || listAmountCents || offerAmountCents || saleAmountCents || 0;
            const price = (amountCents / 100);
            const fromUser = sellingUser || sendingUser || birthingUser || listingUser;
            const toUser = biddingUser || offeringUser || purchasingUser || receivingUser || withdrawingUser;
            let transactionTypeFrom;
            let transactionTypeTo;
            let transactionClassName;
            const href = NiftyObject
              ? `https://niftygateway.com/itemdetail/secondary/${contractAddress}/${tokenId}`
              : `https://niftygateway.com/marketplace?page=1&collection=${contractAddress}`;
    
            if (sellingUser || listingUser) transactionTypeFrom = "sell";
            else if (sendingUser) transactionTypeFrom = "send";
            else if (biddingUser) transactionTypeFrom = "bid";
            else if (birthingUser) transactionTypeFrom = "birth";
    
            if (offeringUser) transactionTypeFrom = "offer";
            else if (purchasingUser) transactionTypeTo = "buy";
            else if (receivingUser) transactionTypeTo = "receive";
            else if (withdrawingUser) transactionTypeTo = "withdraw";
    
            if (transactionTypeFrom === "sell" && !offerAmountCents) {
              if (transactionTypeTo) transactionClassName = "blue"; // Completed sale
              else transactionClassName = "lightblue" // Sale listing
            } else if (transactionTypeFrom === "send") transactionClassName = "yellow"; // Transfer
            else if (transactionTypeFrom === 'birth') transactionClassName = "pink"; // Birth
            else if (transactionTypeFrom === "bid") transactionClassName = "green"; // Bid
            else if (transactionTypeFrom === "offer" || offerAmountCents) transactionClassName = "green"; // Offer
            else if (transactionTypeTo === "withdraw") transactionClassName = "gray"; // Withdrawal
    
            const fromTitle = {
              blue: "Sold by",
              lightblue: "Listed by",
              yellow: "Sent from",
              pink: "Deposited or Minted by",
              green: "Offer from",
            }[transactionClassName] || "";
    
            const toTitle = {
              blue: "Purchased by",
              yellow: "Sent to",
              gray: "Withdrawn by"
            }[transactionClassName] || "";
    
            const transactionLabel = {
              blue: "Sale",
              lightblue: "Listing",
              yellow: "Transfer",
              pink: "Deposit / Mint",
              green: "Bid/Offer",
              gray: "Withdrawal",
            }[transactionClassName];
    
            const transactionIcon ={
              blue: "gavel",
              lightblue: "storefront",
              yellow: "redeem",
              pink: "add_box",
              green: "local_offer",
              gray: "eject",
            }[transactionClassName];

            const newPromise = new Promise((resolve, reject) => {
              try {
                fetchItemDetails(contractAddress, niftyType).then((res) => {
                  if (typeof res === 'string') {
                    const data = JSON.parse(res);
                    const itemCents = data.average_secondary_market_sale_price_in_cents;
                    const tempAveragePrice = itemCents > 0 ? (itemCents / 100) : price;
                    const highestOffer = data.highest_bid_in_cents;
      
                    tempHighestOffers[`${contractAddress}${itemName}`] = highestOffer;
                    tempAverages[itemId] = tempAveragePrice;
                    resolve();
                  }
                });
              } catch (e) {
                console.log(`Error fetching item details: ${e}`)
                reject(e);
              }
            })

            promises.push(newPromise);
    
            return ({
              href,
              fromUser: fromUser
                ? {
                  id: fromUser.id,
                  name: fromUser.name,
                  avatarSrc: fromUser.profile_pic_url,
                }
                : undefined,
              toUser: toUser
                ? {
                  id: toUser.id,
                  name: toUser.name,
                  avatarSrc: toUser.profile_pic_url,
                }
                : undefined,
              contractAddress,
              creatorId,
              description,
              fromTitle,
              itemId,
              itemImageSrc,
              itemName,
              niftyType,
              price,
              timestamp,
              toTitle,
              transactionClassName,
              transactionIcon,
              transactionLabel,
              transactionTypeFrom,
              transactionTypeTo,
            });
          }));

          Promise.all(promises).then(() => {
            setAverages(tempAverages);
            setGotAverages(true);
            setIsLoading(false);
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [hasItemsFetchStarted, fetchItems, fetchItemDetails]);

  const toggleFilter = (color) => {
    const tempFilters = [...filters];

    const thisIndex = tempFilters.findIndex(i => i.color === color);

    tempFilters[thisIndex].isSelected = !tempFilters[thisIndex].isSelected;

    setFilters(tempFilters);
    localStorage.setItem('filters', JSON.stringify(filters));
  }

  const filterElement = (
    <div className="filters-container">
      <h2>Filters</h2>
      {filters.map(({ color, label, icon, isSelected }) => (
        <div key={label} onClick={() => toggleFilter(color)} className={classnames('filter', color, {
          selected: isSelected,
        })}>
          <i className="material-icons">{icon}</i>
          <span>{label}s</span>
        </div>
      ))}
    </div>
  );

  const toggleItemWatched = (ca, itemName, niftyType, newState) => {
    const isNumbered = itemName.indexOf('#') >= 0;
    const truncItemName = isNumbered ? itemName.substring(0, itemName.indexOf('#') - 1) : itemName;
    const tempWatchList = watchList ? [ ...watchList ] : [];
    const idIndex = tempWatchList.findIndex(i => i.ca === ca);
    const localWatchListStr = localStorage.getItem('watchList');

    if (newState) {
        getMarketLow(truncItemName, ca, niftyType)

        tempWatchList.push({
        ca,
        itemName: truncItemName,
        niftyType,
      });
    } else tempWatchList.splice(idIndex, 1);
  
    const watchListStr = JSON.stringify(tempWatchList);

    if (watchListStr && (localWatchListStr !== watchListStr)) localStorage.setItem('watchList', JSON.stringify(watchListStr));

    setWatchList(tempWatchList);
  }

  const toggleItemCollapsed = (id) => {
    const tempKeepOpen = [...keepOpen];
    const idIndex = tempKeepOpen.findIndex(i => i === id);

    if (idIndex >= 0) tempKeepOpen.splice(idIndex, 1);
    else tempKeepOpen.push(id);

    setKeepOpen(tempKeepOpen);
  }

  const resetFilters = () => {setFilters(initFilters)};

  const toggleCollapseAll = () => {
    if (items.some(i => keepOpen.some(f => f === i.itemId))) setKeepOpen([]) // If any items are expanded, close all
    else {
      const tempKeepOpen = items.map(i => i.itemId);

      setKeepOpen(tempKeepOpen);
    }

  };

  const getHighestOffer = (ca, niftyType) => {
    try {
      return fetchItemDetails(ca, niftyType).then((res) => {
        if (res) {
          const data = JSON.parse(res);
          const highestOffer = data.highest_bid_in_cents;
          const lastSale = data.last_sale_amount_in_cents;

          return ({
            highestOffer,
            lastSale
          });
        }
      });
    } catch (e) {
      return console.log(`Error fetching details: ${e}`);
    }
  }

  const getMarketLow = (ca, niftyType) => {
    try {
      return fetchItemMarketLow(ca, `${niftyType}`).then((res) => {
        if (res) {
          const data = JSON.parse(res);
        
          if (data.results && data.results[0]) {
            const marketLow = data.results[0].price_in_cents;

            return marketLow.raw;
          }
        }
      });
    } catch (e) {
      console.log(`Error fetching market low: ${e}`);
    }
  }

  const getItemPrices = async ({ca, niftyType}) => {
    const marketLow = await getMarketLow(ca, niftyType);
    const hData = await getHighestOffer(ca, niftyType);

    if (hData) {
      const { highestOffer, lastSale } = hData;

      return { highestOffer, lastSale, marketLow };
    }
  }

  useEffect(() => {
    if (gotAverages && !gotNotated) {
      const tempItems = notatedItems(items, averages);

      setItems(tempItems);

      setGotNotated(true);
    }
  }, [gotAverages, gotNotated, items, averages]);

  useEffect(() => {
    if (!items.length && !hasItemsFetchStarted) {
      setHasItemsFetchStarted(true);
      itemGet();
    }
  }, [items, fetchItems, itemGet, hasItemsFetchStarted]);

  useEffect(() => {
    const localFiltersStr = localStorage.getItem('filters');
    const filtersStr = JSON.stringify(filters);

    if (localFiltersStr !== filtersStr) localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  return (
    <div className="page-container">
      <div className="activity-container">
        {filterElement}

        <div className="mid-container">
          <button onClick={() => setShowFiltersModal(true)} className="filter-menu">
            <i className="material-icons">filter_list</i>
          </button>
          <button onClick={() => toggleCollapseAll(true)} className="main-toggle">
            <i className="material-icons">
              {items.some(i => keepOpen.some(f => f === i.itemId))
                ? 'unfold_less'
                : 'unfold_more'
              }
            </i>
          </button>
          <button onClick={() => setShowWatchlistModal(true)} className="watchlist-menu">
            <i className="material-icons">star</i>
          </button>
          <button onClick={() => itemGet(true)} className={classnames('refresh', {
            loading: isLoading,
          })}>
            <i className="material-icons">refresh</i>
          </button>

          <div className="table-container">
            {(!items.some(i => filters.some(f => f.color === i.transactionClassName && f.isSelected))) && (
              <>
                {Boolean(items.length)
                  ? (
                    <div>
                      <p>No items. Your filters might be too restrictive.</p>
                      <button onClick={resetFilters}>Reset Filters</button>
                    </div>
                  )
                  : <p>Loading</p>
                }
              </>
            )}

            {compact(items.map((item, i) => {
              const show = filters.some(f => f.color === item.transactionClassName && f.isSelected);
              const unfolded = keepOpen.some(f => f === item.itemId);
              const date = new Date(item.timestamp); // ISO time to Date
              const milliseconds = date.getTime(); // Date to unix milliseconds
              const isWatched = watchList && watchList.some(f => {
                const isNumbered = item.itemName.indexOf('#') >= 0;
                const truncItemName = isNumbered ? item.itemName.substring(0, item.itemName.indexOf('#') - 1) : item.itemName;

                return f.ca === item.contractAddress && f.itemName === truncItemName
              });

              if (!show) return null;

              return (
                <Item
                  averagePrice={averages[item.itemId]}
                  contractAddress={item.contractAddress}
                  milliseconds={milliseconds}
                  key={`item-${item.itemId}i${i}`}
                  isExpanded={unfolded}
                  isWatched={isWatched}
                  toggleItemCollapsed={toggleItemCollapsed}
                  toggleItemWatched={toggleItemWatched}
                  unfolded={unfolded}
                  {...item}
                />
              );
            }))}
          </div>
        </div>

        <OfferWatch
          watchList={watchList}
          getItemPrices={getItemPrices}
          toggleItemWatched={toggleItemWatched}
          refreshAll={() => setShouldReloadWatchlist(true)}
          resetShouldReloadWatchlist={() => setShouldReloadWatchlist(false)}
          shouldReloadWatchlist={shouldReloadWatchlist}
          noMobile
        />

        <Modal open={showFiltersModal} onClose={() => setShowFiltersModal(false)}>
          <div className="modal">
            <i className="material-icons" onClick={() => setShowFiltersModal(false)}>close</i>
            {filterElement}
          </div>
        </Modal>

      <Modal open={showWatchlistModal} onClose={() => setShowWatchlistModal(false)}>
        <div className="modal">
          <i className="material-icons" onClick={() => setShowWatchlistModal(false)}>close</i>

          <OfferWatch
            watchList={watchList}
            getItemPrices={getItemPrices}
            toggleItemWatched={toggleItemWatched}
            refreshAll={() => setShouldReloadWatchlist(true)}
            resetShouldReloadWatchlist={() => setShouldReloadWatchlist(false)}
            shouldReloadWatchlist={shouldReloadWatchlist}
          />
        </div>
      </Modal>
      </div>
    </div>
  );
}

export default Activity;