import React, { Component } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import Challenges from './views/Challenges';
import Items from './views/Items';
import Missions from './views/Missions';
import News from './views/News';
import Wishlists from './views/Wishlists';

import {
  AppBar,
  Tab,
  Tabs,
  TextField
} from '@material-ui/core';

import { firebaseConfig } from './constants.js';

import './styles.scss';

export default class Console extends Component {
  firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  db = this.firebaseApp.firestore();

  state = {
    selectedTab: 0,
    userValues: { email: '', password: '' }
  };

  async componentDidMount() {
    const user = firebase.auth().currentUser;
    
    await this.getCurrentSeason();

    this.setState({ user }, () => {
      this.fetchMissions();
    });
  }

  render () {
    // db.collection('item-shop').where('category', '==', 'loading-screens').get().then(results => {
    //   console.log(results.docs.length);
    // })

    const {
      currentSeason,
      missions,
      selectedTab,
      user,
      userValues: { email, password },
    } = this.state;

    if (!user || !user.email) return (
      <div>
        <TextField className="input" id="email" label="Email" value={email} onChange={event => this.handleUserChange(event, 'email')} />
        <TextField className="input" type="password" id="password" label="Password" value={password} onChange={event => this.handleUserChange(event, 'password')} />
        <button onClick={this.logIn}>Log in</button>
      </div>
    );

    return (
      <div key="console-container" className="console-container">

        <AppBar className="app-bar" position="static">
          <Tabs value={selectedTab} onChange={this.handleTabChange}>
            <Tab label="Wishlists" />
            <Tab label="Item Shop" />
            <Tab label="Missions" />
            <Tab label="Challenges" />
            <Tab label="News" />
          </Tabs>
        </AppBar>

        <div className="console-content">
          {selectedTab === 0 &&
            <Wishlists />
          }
          {selectedTab === 1 &&
            <Items />
          }
          {selectedTab === 2 &&
            <Missions season={currentSeason} missions={missions} refreshMissions={this.fetchMissions} />
          }
          {selectedTab === 3 &&
            <Challenges missions={missions} />
          }
          {selectedTab === 4 &&
            <News />
          }
        </div>
      </div>
    );
  }

  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
  };

  fetchItemShop = () => {
    // won't be able to use this until they allow CORS

    window.fetch('https://api.fortnitetracker.com/v1/store', {
      headers: {
        'TRN-Api-Key': '5bbf22b2-bf45-445b-8a48-27ef00603aec'
      }
    }).then(response => {
      console.log('res', response);
    })
  }

  handleUserChange = (event, target) => {
    const { userValues } = this.state;

    userValues[target] = event.target.value;
    this.setState({ userValues });
  }

  closeConsole = () => {
    this.props.onClose();
  }

  logIn = (callback) => {
    const { userValues } = this.state;

    firebase.auth().signInWithEmailAndPassword(userValues.email, userValues.password)
    .then(() => {
      const user = firebase.auth().currentUser;

      this.setState({ user });
    })
    .catch(error => {
      this.setState({ isError: true, errorMessage: error.message }); // TODO: log error.code
    });
  }

  getCurrentSeason = () => {
    return this.db.collection('misc').doc('current-season').get()
    .then(seasonData => {
      if (seasonData) {
        const { value: currentSeason } = seasonData.data();

        this.setState({ currentSeason });
      }
    })
  }

  fetchMissions = () => {
    const { currentSeason } = this.state;

    if (!currentSeason) return;

    this.db.collection('missions').where('season', '==', currentSeason).get().then(result => {
      const missions = result.docs.map(item => item.data());

      this.setState({ missions });
    });
  }
}
