export const getPercentChange = (price, averagePrice) => {
  const diff = price - averagePrice;

  return diff !== 0 ? diff / averagePrice * 100 : 0;
}

export const notatedItems = (items, averages) => {
  let tempItems = [...items];

  tempItems.reverse();

  for (let i = 0; i < tempItems.length; i++) {
    const tempItem = {
      ...tempItems[i],
      streakFrom: 0,
    };
    const { creatorId, fromUser, itemId, itemName, price, transactionClassName, toUser } = tempItem;
    const average = averages[itemId];
    const percentChange = getPercentChange(price, average);
    const prevItem = i > 0 ? tempItems[i - 1] : {};
    const hashIndex = itemName.indexOf('#') - 1;

    const trimmedItemName = hashIndex >= 0 ? itemName.substring(0, hashIndex) : itemName;

    tempItem.trimmedItemName = trimmedItemName;

    if (
      (percentChange < 0 && ['lightblue', 'blue'].some(c => c === transactionClassName))
      || (percentChange > 0 && transactionClassName === 'green')
    ) {
      tempItem.isAgainstTrend = true;
    }
  
    if (['lightblue', 'blue'].some(c => c === transactionClassName)) {
      if (percentChange <= -50) tempItem.isSteal = true;
      else if (percentChange <= 30 && price <= 150) tempItem.isGreatValue = true;
    }

    if (prevItem && ['lightblue', 'blue', 'green'].some(c => c === transactionClassName)) {
      if (prevItem.trimmedItemName === trimmedItemName) tempItem.streakItem = prevItem.streakItem ? prevItem.streakItem + 1 : 2;
      
      if (prevItem.creatorId === creatorId) {
        tempItem.streakCreator = prevItem.streakCreator ? prevItem.streakCreator + 1 : 2;
      }

      if (fromUser && prevItem.fromUser?.id === fromUser.id) {
        if (['lightblue', 'blue'].some(c => c === transactionClassName)) {
          tempItem.fromUser.streakSeller = prevItem.fromUser.streakSeller ? prevItem.fromUser.streakSeller + 1 : 2;
        } else if (transactionClassName === 'green') {
          tempItem.fromUser.streakBidder = prevItem.fromUser.streakBidder ? prevItem.fromUser.streakBidder + 1 : 2;
        }
      }

      if (transactionClassName === 'blue' && toUser && prevItem.toUser?.id === toUser.id) {
        tempItem.toUser.streakBuyer = prevItem.toUser.streakBuyer ? prevItem.toUser.streakBuyer + 1 : 2;
      }
    }

    tempItems[i] = tempItem;
  }

  tempItems.reverse();

  return tempItems;
}