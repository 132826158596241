import React from 'react';

import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const PreviousScoreboard = ({ userId, board, isScoreboardLoading }) => {
  const classes = useStyles();
  const countedEntries = [];
  const anonymousEntries = [];

  board.forEach(entry => {
    if (entry.settings?.includeOnScoreboard) countedEntries.push(entry);
    else anonymousEntries.push(entry);
  });

  board.sort((a, b) => b.totalPrevDistance - a.totalPrevDistance);

  const allMiles = countedEntries.length && countedEntries.reduce((a, b) => a + +b.totalPrevDistance, 0);

  return (
    <div className='scoreboard-container'>
      {!board || !board[0].settings
        ? 'Loading previous month\'s scoreboard...'
        : (
            <>
              <div
                className={classnames('entry-container', {
                  refreshing: board && isScoreboardLoading,
                })}
              >
                {
                  board.map(({
                    firstName,
                    isOpen,
                    lastName,
                    settings: {
                      includeOnScoreboard,
                    },
                    totalPrevDistance: distance,
                    userId: entryId,
                  }, i) => (
                    <Card className={classnames(classes.root, 'scoreboard-entry', {
                      open: isOpen,
                      self: userId === entryId,
                      show: includeOnScoreboard,
                    })} key={entryId}>
                      <CardContent className="card-contents">
                        <div className="scoreboard-name">
                          <span className={classnames('content', {
                            self: userId === entryId,
                          })}>{`${firstName} ${lastName}`}</span>
                          <span>{userId === entryId ? '(You)' : ''}</span>
                          {+distance >= 100 && <img className="crown" src="/assets/crew/crown-sm.png" alt="Crew crown" />}
                        </div>
                        <div className="distance">{`${(+distance).toFixed(2)} miles`}</div>
                      </CardContent>
                    </Card>
                  ))
                }
              </div>

              <div className="total">
                <div>
                  <p>Together, {countedEntries.length} of us went {allMiles.toFixed(0)} miles.</p>
                  {Boolean(anonymousEntries.length) && <p>(Not including {anonymousEntries.length} anonymous {anonymousEntries.length === 1 ? 'entry' : 'entries'})</p>}
                </div>
              </div>
            </>
          )
      }
    </div>
  );
}

export default PreviousScoreboard;
