import React from 'react';

import { TwitterTweetEmbed } from 'react-twitter-embed';

import { Writeup } from '../../components/Writeup';

import './styles.scss';

export const FortniteDashboard = () => {
    const overview = [
        'FortniteDashboard.com was a website where Fortnite players could see interactive challenge maps, find teammates, and create and share Fortnite item shop wishlists.',
        'It was first introduced here in June 2019, then interated upon and rereleased at FortniteDashboard.com in July 2019.',
        'At its peak, the site saw 5,000 unique visitors per day.'
    ]

    const problem = [
        'In June 2019, Epic games started Season 9 of Fortnite, and introduced "fortbytes", a new type of collectible item. Every day throughout the season (which lasted three months) one or two new fortbytes were unlocked.',
        'Each fortbyte came with a clue about where to find it, the rest was up to players to explore the game and hope they\'d find the secret location of each one. However, as the season went on, fortbytes piled up. People would log in after a few days of not playing to find that they had a handful of them to find.',
        'Some players on reddit and twitter started making maps of all known fortbyte locations. This worked well for the first ten or so locations, but soon the maps were overwhelmed. People wanted an interactive solution.',
        'In late May, I started working on adding all known fortbyte locations to a database. In early June, I launched my interactive map here, at nataliebeaumont.com/fortbyte-map. Anyone could see all locations, and click on a location to see a screenshot and description of exactly how to find each fortbyte.',
    ];

    const reception = [
        'I thought this tool might be useful to other fortnite players, so a few weeks later I made a post about it on the popular fortnite subreddit, /r/fortniteBR.',
        'The post did well, getting to the front page of the subreddit before being deleted (for linking to an outside source).',
        'I immediately saw a huge spike in users on my site. Previously, I was seeing 50 to 100 unique visitors per day. On June 25th I saw over 5,000 unique visitors.',
        'People were spending an average of eight minutes on my site. Based on comments on my reddit post, they\'d start up Fortnite and keep my map open, either on their phone or a second monitor, and use it to quickly get their fortbytes done for the day.'
    ];

    const iteration = [
        'The response on reddit was so good that I decided to spin the site away from my personal page, and onto its own domain: FortniteDashboard.com.',
        'I already had some ideas for next steps, and redditors were also asking for specific features, like user log ins, so my work was cut out for me.',
        'On July 18th, I launched the new site, with additional features: User account creation, profile badges, a friend finder, a weekly challenges page, and a home page featuring a few smalltime streamers another small Fortnite-related websites.',
        'I also added a widget to the old site so that people could transfer their fortbyte data out of their browser and into their new account on the new site.',
        'People quickly moved to the new site. I got over 1,000 user signups by the end of season 9.',
        'And then season 9 ended, and Epic decided not to bring fortbytes back.'
    ];

    const mediaCoverage = [
        'Some days I\'d check google analytics and see an unusual spike in users. After digging around the internet, or tracing back links to my tweets, I found that a few media sites were writing about my website.',
        'Here are the ones I was able to find.'
    ];

    const pivoting = [
        'Without fortbytes in the game, there wasn\'t as pressing a need for an interactive map. Sure, there were still weekly missions, but those weren\'t nearly as time consuming, and most people are able to do them without help.',
        'I worked on adding a new feature - wishlists.',
        'Fortnite has an expansive armory of skins, backpacks, pickaxes, gliders, etc., but only ~20 are ever available for purchase in the item shop at a time.',
        'I found an API that provides the list of daily available items, and used it to display the item shop on my site\'s homepage.',
        'Then I started working on a feature that was missing from the game itself - the ability to get a notification when an item you wanted appeared in the item shop that day.',
        'I created my own searchable database with every known fortnite item in it, then let users add items to their wishlist.',
        'Using a google cloud cron job, I automated the store on my site to update on its own every day, and compile a list of people who had desired items in the shop.',
        'The same job then uses mailgun to send out emails to each person with a list of items they wanted that are currently in the shop.',
        'I also made these wishlists publishable, so you can your wishlist with friends.'
    ];

    const sunset = [
        'The huge amount of traffic I saw during the fortbyte days never came back.',
        'Then, in Chapter 2 Season 2, Epic made a massive overhaul of their mission system - they added an interactive map right inside the game.',
        'With that change, the major draw of my site is completely unnecessary (but I\'m glad they did it - it\'s a great, needed improvement to the game itself).',
        'Since my wishlist system is completely automated, I\'m leaving that part up (at least until Epic adds one to the game).',
        'This was my first time producing something of this scale completely on my own. It was a great experience for me, coming up with my own designs, programming everything myself, doing all my own social media PR, and interacting with users. It feels good to have made something that thousands of people used.',
        'In the end, I wasn\'t able to monetize the project (though I did get some donations that helped with hosting early on). That, and the fact that the site\'s success depends completely on a third party (Epic) and their deficits, means that there isn\'t really much further I could go with this project.'
    ];

    return (
        <div className="fd-writeup">
            <Writeup
                title='FortniteDashboard.com'
                panes={[
                    {
                        className: "overview",
                        left: (
                            <div className="content">
                                <h3>Overview</h3>

                                {overview.map((item, itemIndex) => (
                                    <p key={`overview-${itemIndex}`}>{item}</p>
                                ))}
                            </div>
                        ),
                        right: (
                            <div>
                                <TwitterTweetEmbed tweetId={'1166809207355539458'} />
                            </div>
                        )
                    },
                    {
                        left: (
                            <div className="content">
                                <h3>The Problem</h3>

                                {problem.map((item, itemIndex) => (
                                    <p key={`problem-${itemIndex}`}>{item}</p>
                                ))}
                            </div>
                        ),
                        right: (
                            <div>
                                <TwitterTweetEmbed tweetId={'1135122496636694528'} />
                            </div>
                        )
                    },
                    {
                        className: "reception",
                        left: (
                            <div className="content">
                                <h3>Initial Reception</h3>

                                {reception.map((item, itemIndex) => (
                                    <p key={`reception-${itemIndex}`}>{item}</p>
                                ))}
                            </div>
                        ),
                        right: (
                            <div>
                                <img className="full" src="https://i.imgur.com/qyRxnsH.png" alt="positive comments on my reddit post" />
                                <img src="https://i.imgur.com/jTsJwnT.png" alt="google analytics screenshot for June 2019, showing 5,015 users on June 25th" />
                            </div>
                        )
                    },
                    {
                        className: "mediaCoverage",
                        left: (
                            <div className="content">
                                <h3>Media Coverage</h3>

                                {mediaCoverage.map((item, itemIndex) => (
                                    <p key={`mediaCoverage-${itemIndex}`}>{item}</p>
                                ))}

                                <div>
                                    <a
                                        href="https://us.blastingnews.com/gaming/2019/07/fortnite-battle-royale-there-are-ways-to-get-fortbytes-in-less-than-an-hour-002942787.html"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Blasting News US: There are ways to get Fortbytes in less than an hour
                                    </a>
                                    <a href="https://fortniteintel.com/solve-fortnite-season-9-challenges/21839/" target="_blank" rel="noopener noreferrer">
                                        Fortnite Intel: How to complete all the Fortbyte Challenges
                                    </a>
                                </div>
                            </div>
                        ),
                        right: (
                            <div>
                                <img className="half" src="https://fortniteintel.com/wp-content/uploads/2018/01/filogo.png" alt="Fortnite Intel logo" />
                                <img className="half" src="https://pbs.twimg.com/profile_images/874179276874166272/055eiNEY_400x400.jpg" alt="Blasting News logo" />
                            </div>
                        )
                    },
                    {
                        className: "iteration",
                        left: (
                            <div className="content">
                                <h3>Iteration</h3>

                                {iteration.map((item, itemIndex) => (
                                    <p key={`iteration-${itemIndex}`}>{item}</p>
                                ))}
                            </div>
                        ),
                        right: (
                            <div>
                                <TwitterTweetEmbed tweetId={'1152048177387692032'} />
                            </div>
                        )
                    },
                    {
                        className: "pivoting",
                        left: (
                            <div className="content">
                                <h3>Pivoting</h3>

                                {pivoting.map((item, itemIndex) => (
                                    <p key={`pivoting-${itemIndex}`}>{item}</p>
                                ))}
                            </div>
                        ),
                        right: (
                            <div>
                                <TwitterTweetEmbed tweetId={'1234363138297827328'} />
                            </div>
                        )
                    },
                    {
                        className: "sunset",
                        left: (
                            <div className="content">
                                <h3>Sunset</h3>

                                {sunset.map((item, itemIndex) => (
                                    <p key={`sunset-${itemIndex}`}>{item}</p>
                                ))}
                            </div>
                        )
                    },
                ]}
            />
        </div>
    )
}