import React, { useCallback, useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { compact, uniqBy, xor } from 'lodash';
import moment from 'moment';
import ReactHighcharts from 'react-highcharts';

import Card from '@material-ui/core/Card';

import { excerciseIconMap } from '../utils';

import './explore.scss';

const Explore = ({ activities }) => {
  const days = moment().diff(moment().startOf('month'), 'days');
  const xAxis = [];

  for (let i = 1; i <= days; i++) {
    xAxis.push(moment(`${i} 05 2020`, 'D MM YYYY').format('D MMM'));
  }

  const initialConfig = {
    chart: {
      height: '50%',
      type: 'line',
    },
    legend: {
      enabled: false,
    },
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: 'white',
      enabled: true,
      valueDecimals: 2,
    },
    xAxis: {
        categories: xAxis,
        labels: {
          enabled: false
        },
        visible: false,
    },
    yAxis: {
      title: {
          enabled: false,
      },
      labels: {
        enabled: false
      },
      visible: false,
    },
  };

  const [config, setConfig] = useState(initialConfig);
  const [allStats, setAllStats] = useState();
  const [selectedExercise, setSelectedExercise] = useState();
  const [series, setSeries] = useState();
  const [isSeriesLoaded, setIsSeriesLoaded] = useState(false);

  const explorer = useRef(null); 

  const compileAllStats = items => {
    const allStatsTemp = {};

    items.forEach(({ activities, profile }) => {
      activities.forEach(activity => {
        if (!allStatsTemp[activity.exerciseType]) allStatsTemp[activity.exerciseType] = [];
  
        allStatsTemp[activity.exerciseType].push({ ...activity, userId: profile.userId });
      });
    });

    setAllStats(allStatsTemp);
  }

  const compileSeries = useCallback((selectedExercise) => {
    let max = 0;

    const seriesTemp = compact(activities.map(({ activities: userActivities, profile }) => {
      let cumulative = null;
  
      const data = xAxis.map(day => {
        const dayActivities = userActivities.filter(activity => {
          const isSameDay = moment(activity.startDate).isSame(moment(`${day} 2020`, 'DD MMM YYYY'), 'day');
          const isSelectedExercise = selectedExercise && activity.exerciseType.toLowerCase() === selectedExercise.toLowerCase();

          return isSameDay && (isSelectedExercise || !selectedExercise);
        });
  
        const dayTotal = dayActivities.reduce((a, b) => a + b.distance, 0);
  
        if (cumulative) cumulative += dayTotal;
        else if (dayTotal) cumulative = dayTotal;
  
        return cumulative;
      });
  
      if (cumulative > max) max = cumulative;

      if (!data.some(item => item)) return undefined;
  
      return (
        {
          name: `${profile.firstName} ${profile.lastName}`,
          cumulative,
          crisp: false,
          data,
          marker: {
            enabled: false,
          },
        }
      )
    }));

    setConfig({...config, yAxis: {...config.yAxis, max}});

    seriesTemp.sort((a, b) => b.cumulative - a.cumulative);

    const frequency = seriesTemp.length ? 4 / seriesTemp.length : 0.1;

    for (let i = 0; i < seriesTemp.length; i++) {
      const red = Math.sin(frequency * i + 4) * 127 + 128;
      const green = Math.sin(frequency * i + 2) * 127 + 128;
      const blue = Math.sin(frequency * i + 0) * 127 + 128;
      const rgbColor = `rgb(${red}, ${green}, ${blue})`

      seriesTemp[i].lineColor = rgbColor;
      seriesTemp[i].color = rgbColor;
    }

    setSeries(seriesTemp);
  }, [activities, config, xAxis]);

  const selectExercise = (event, type) => {
    event.stopPropagation();
    setSelectedExercise(type);
  }

  const getDataCards = stats => {
    return Object.keys(stats).map(exerciseType => {
      const activityList = stats[exerciseType];
      const totalDistance = activityList.reduce((a, b) => a + b.distance, 0).toFixed(2);
      const athletes = uniqBy(activityList, item => item.userId);
  
      return (
        <Card
          className="data-card"
          key={`card-${exerciseType}`}
          onClick={event => selectExercise(event, exerciseType)}
        >
          <div>
            <p className="text">{totalDistance} <span>mi</span></p>
            <p className="material-icons">{excerciseIconMap(exerciseType)}</p>
            <p className="text">{athletes.length} <span>{athletes.length === 1 ? 'person' : 'people'}</span></p>
          </div>
        </Card>
      );
    });
  }

  useEffect(() => {
    if (activities && !allStats) {
      compileAllStats(activities);
      compileSeries(); 
    }
  }, [activities, allStats, compileSeries]);

  useEffect(() => {
    if (series && xor(series, config.series).length) {
      setConfig({...config, series});
    }
  }, [config, series]);

  useEffect(() => {
    if (config.series && config.series[0].data) setIsSeriesLoaded(true);
  }, [config]);

  useEffect(() => {
    compileSeries(selectedExercise);
  }, [compileSeries, selectedExercise]);

  return (
    <>
      <div className="chart">
        {isSeriesLoaded && <ReactHighcharts config={{...config}} ref={explorer} />}
      </div>
      
      <div className="data-container">
        {allStats && getDataCards(allStats)}
        <Card className={classnames('data-card big', {
          show: selectedExercise,
        })}>
          <p className="material-icons exercise">{excerciseIconMap(selectedExercise)}</p>
          <div className="athletes">
            {series?.map(line => (
              <p key={line.name} style={{ borderColor: line.color, color: line.color }}>
                <span>{line.name}</span>
                <span>{line.cumulative.toFixed(2)}</span>
              </p>
            ))}
          </div>
          <i className="material-icons close" onClick={event => selectExercise(event)}>close</i>
        </Card>
      </div>
    </>
  )
};

export default Explore;