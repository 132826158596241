import React, { useEffect, useState } from 'react';

import classnames from "classnames";

import Modal from '@material-ui/core/Modal';

import AllSettings from './components/AllSettings';
import NFTFilters from "./components/NFTFilters";
import ResultsTable from "./components/ResultsTable";

import './styles.scss';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const NFTAggregator = () => {
  const [feedSettings, setFeedSettings] = useState({
    creator: [],
    series: [],
    source: [],
  });
  const [hasEntered, setHasEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNext, setIsLoadingNext] = useState(false);
  const [results, setResults] = useState(); // Array of nfts from endpoint
  const [selectedNFT, setSelectedNFT] = useState(); // Empty or selected nft
  const [showAddress] = useState(false);
  const [showAllSettings, setShowAllSettings] = useState();
  const [settings, setSettings] = useState({
    isImmersive: true,
  });

  const openMenu = nftId => {
    if (!results) return null;

    const tempSelectedNFT = results.find(result => result.id.raw === nftId);

    if (!selectedNFT || selectedNFT.id.raw !== nftId) setSelectedNFT(tempSelectedNFT);
  }

  const getNFTs = () => {
    const raw = { isNatalie: "hi", page: 1, amount: 6 }
    const body = JSON.stringify(raw, getCircularReplacer());
    let headers = new Headers();

    headers.append("Accept", "*/*");
    headers.append("Accept-Language", "en-US,en;q=0.5");
    headers.append("Content-Type", "text/plain");
console.log('sending request')
    return fetch('https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/function-1',
      {
        body,
        headers,
        method: 'POST',
        redirect: 'follow'
      })
      .then(res => {
        const text = res.text();
        return text;
      })
      .catch(e => console.log(`Error fetching results: ${e}`));
  }

  const loadNext = () => {
    setIsLoadingNext(true);

    getNFTs().then((res) => {
      const response = JSON.parse(res);
      const nfts = response;

      setResults(nfts.map(nft => ({ ...nft, source: "Nifty Gateway"})));
      setIsLoadingNext(false)
    });
  }

  const addFeedFilter = (type, name) => {
    const tempFeedSettings = {...feedSettings};
    const tempFeedSettingsType = new Set(feedSettings[type]);

    tempFeedSettingsType.add({ label: name, value: true });
    tempFeedSettings[type] = [...tempFeedSettingsType];
    setFeedSettings({...tempFeedSettings});
  }

  const removeFeedFilter = (type, name) => {
    const tempFeedSettings = {...feedSettings};
    const filterIndex = tempFeedSettings[type].findIndex(item => item.label === name);

    tempFeedSettings[type].splice(filterIndex, 1);
    console.log('tempFeedSettings', tempFeedSettings)
    setFeedSettings({...tempFeedSettings});
  }

  const updateFeedFilter = (type, name, value) => {
    const tempFeedSettings = {...feedSettings};
    const filterIndex = feedSettings[type].findIndex(filter => filter.label === name);

    if (filterIndex < 0) return;

    tempFeedSettings[type][filterIndex].value = value;
    setFeedSettings(tempFeedSettings);
  }

  const updateSettings = (setting, value) => {
    const tempSettings = { ...settings };

    tempSettings[setting] = value;
    setSettings(tempSettings);
  }

  const onModalClose = () => {
    setSelectedNFT();
    setShowAllSettings(false);
  }

  useEffect(() => {
    console.log('ue index')
    const fetchNFTs = () => {
      getNFTs().then((res) => {
        const response = JSON.parse(res);
        const nfts = response;

        setResults(nfts.map(nft => ({ ...nft, source: "Nifty Gateway"})));
        setSettings({
          isImmersive: false,
        })
        setIsLoading(false);
      });
    };

    fetchNFTs();
  }, []);

  return (
    <div className={classnames('nft-aggregator', {
      immersive: settings.isImmersive,
    })}>
      <header className={classnames('main-header', { hide: hasEntered })}>
        <h2>NFT Market Stream</h2>

        <div className="description">
          <p>Immersive, filterable market stream.</p>
          <p>Discover new platforms and creators.</p>
        </div>

        <i className="settings material-icons" onClick={() => setShowAllSettings(true)}>settings</i>
      </header>

      <div className="results-container">
        <ResultsTable
          loadNext={loadNext}
          openMenu={openMenu}
          results={results}
          isImmersive={settings.isImmersive}
          isLoadingNext={isLoadingNext}
        />
      </div>

      <div className="filters-container"></div>

      <button onClick={() => setHasEntered(true)} className={classnames('enter', {
        loaded: !isLoading,
        hide: hasEntered,
      })}>Enter</button>

      <div className={classnames('footer', {
        loaded: hasEntered,
      })}>
        <div className="address">{`Ξ${showAddress ? ' 0xC21e4d5A5E8D85d18Dfbb07Ed05BDAf69Af53E3a' : ''}`}</div>
        <button onClick={loadNext}>Next 6</button>
      </div>

      <Modal className="nft-modal" open={Boolean(selectedNFT || showAllSettings)} onClose={onModalClose}>
        <>
          {selectedNFT && (
            <NFTFilters
              addFeedFilter={addFeedFilter}
              feedSettings={feedSettings}
              hide={showAllSettings}
              removeFeedFilter={removeFeedFilter}
              selectedNFT={selectedNFT}
              setShowAllSettings={setShowAllSettings}
              updateFeedFilter={updateFeedFilter}
            />
          )}

          <AllSettings 
            hide={!showAllSettings} 
            feedSettings={feedSettings}
            removeFeedFilter={removeFeedFilter}
            hasSelecteNFT={Boolean(selectedNFT)}
            settings={settings}
            updateFeedFilter={updateFeedFilter}
            updateSettings={updateSettings}
          />
        </>
      </Modal>
    </div>
  );
};

export default NFTAggregator;