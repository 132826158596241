export const projectID = 'fortnite-dashboard-b545a';

export const firebaseConfig = {
  apiKey: 'AIzaSyCWfU3eDgB2K1O4TSDXv_HP-Z0WoxW4nkY',
  authDomain: 'fortnite-dashboard-b545a.firebaseapp.com',
  databaseURL: 'https://fortnite-dashboard-b545a.firebaseio.com',
  projectId: 'fortnite-dashboard-b545a',
  storageBucket: 'fortnite-dashboard-b545a.appspot.com',
  messagingSenderId: '743744717344',
  appId: '1:743744717344:web:58ef24460740f03d'
}

export const ALGOLIA_APP_ID = 'Z8TK6Z4XE0';
export const ALGOLIA_API_KEY = '9a8d8e908150b000376b7e8faf429ca4';
