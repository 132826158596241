import React, { useState } from 'react';

import classnames from 'classnames';

import "../styles.scss";

const NFTContainer = (props) => {
  const { isImmersive, isSkeleton } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  if (isSkeleton) {
    const rand = Math.floor(Math.random() * Math.floor(12));

    return (
      <div className={classnames('nft-container skeleton', { immersive: isImmersive })}>
        <div className="img" style={{ animationDelay: `${rand * 100}ms` }}></div>
        <div className="price">
          <div className="logo"></div>
          <span>$<span className="wavy-davy"></span></span>
          <i className="material-icons">more_vert</i>
        </div>
      </div>
    )
  }

  const {
    contract_address: { raw: contractAddress },
    id: { raw: nftId },
    image_url: { raw: rawImageUrl },
    openMenu,
    price_in_cents: { raw: cents },
    source,
    token_id_or_nifty_type: { raw: tokenLink }
  } = props;

  if (!rawImageUrl) return null;
  const isVideo = rawImageUrl.endsWith('.mp4');
  const splitIndex = rawImageUrl.indexOf('/upload')
  let imageUrl = rawImageUrl.split('');
  const addString = isVideo ? '/vc_auto,w_200' : '/q_jpegmini,w_200';
  const price = (cents / 100).toFixed(2);
  const srcLogo = {
    'Nifty Gateway': '/assets/images/niftygateway-logo.png'
  }[source]

  const link = {
    'Nifty Gateway': `https://niftygateway.com/itemdetail/secondary/${contractAddress}/${tokenLink}`
  }[source];

  imageUrl.splice(splitIndex + 7, 0, addString);
  imageUrl = imageUrl.join('');

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    if (!event.target.href) openMenu(nftId)
  }
  const rand = Math.floor(Math.random() * Math.floor(1200));
  const img = new Image();
  img.onload = () => setIsImageLoaded(true);
  img.src = imageUrl;

  return (
    <div className={classnames('nft-container', { immersive: isImmersive })} onClick={event => handleOpenMenu(event)}>
      <a href={link} target="_blank" rel="noopener noreferrer" className="material-icons">reply</a>

      {isVideo
        ? (
          <video className='nft-video' autoPlay loop muted>
              <source src={imageUrl} type='video/mp4' />
          </video>
        )
        : isImageLoaded
          ? <div className="nft-image" style={{ backgroundImage: `url(${img.src})` }} />
          : <div className="img" style={{ animationDelay: `${rand}ms` }}></div>
      }

      <div className="price">
        <img src={srcLogo} alt={source} />
        <span>{`$${price}`}</span>
        <i className="material-icons">more_vert</i>
      </div>
    </div>
  );
}

export default NFTContainer;