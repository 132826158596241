import React from 'react';

import { Link } from 'react-router-dom';

import './styles.scss';

export const Writeup = ({ panes, title }) => {
    return (
        <div className="writeup-container">
            <div className="writeup">
                <header>
                    <Link to="/" className="material-icons button">arrow_back</Link>
                    <h2>{title}</h2>
                </header>

                {panes.map((pane, paneIndex) => (
                    <div className={`pane ${pane.className}`} key={`pane-${paneIndex}`}>
                        {Boolean(pane.left) && (
                            <div className="left">
                                {pane.left}
                            </div>
                        )}
                        
                        {Boolean(pane.right) && (
                            <div className="right">
                                {pane.right}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}