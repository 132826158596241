import React, { useEffect, useState } from 'react';

import classnames from 'classnames';

import Filter from './Filter';

const NFTFilters = ({
  addFeedFilter,
  feedSettings,
  hide,
  removeFeedFilter,
  selectedNFT,
  setShowAllSettings,
  updateFeedFilter,
}) => {
  const [hasFilters, setHasFilters] = useState(Boolean([...feedSettings.creator, ...feedSettings.series, ...feedSettings.source].length))
  const [link, setLink] = useState({
    'Nifty Gateway': `https://niftygateway.com/itemdetail/secondary/${selectedNFT.contract_address.raw}/${selectedNFT.token_id_or_nifty_type.raw}`
  }[selectedNFT?.source]);

  const [creatorName, setCreatorName] = useState(selectedNFT?.creator_name?.raw);
  const [creatorSetting, setCreatorSetting] = useState(feedSettings.creator.find(creator => creator.label === creatorName));
  const [isCreatorOn, setIsCreatorOn] = useState(!creatorSetting || creatorSetting.value);

  const [seriesName, setSeriesName] = useState(selectedNFT?.project_name?.raw);
  const [seriesSetting, setSeriesSetting] = useState(feedSettings.series.find(item => item.label === seriesName));
  const [isSeriesOn, setIsSeriesOn] = useState(!seriesSetting || seriesSetting.value);
  const [seriesDisplayName, setSeriesDisplayName] = useState(seriesName.split(' by ')[0]);

  const [sourceName, setSourceName] = useState(selectedNFT?.source);
  const [sourceSetting, setSourceSetting] = useState(feedSettings.source.find(item => item.label === sourceName));
  const [isSourceOn, setIsSourceOn] = useState(!sourceSetting || sourceSetting.value);

  useEffect(() => {
    console.log('ue0')
    const tempLink = {
      'Nifty Gateway': `https://niftygateway.com/itemdetail/secondary/${selectedNFT.contract_address.raw}/${selectedNFT.token_id_or_nifty_type.raw}`
    }[selectedNFT?.source];

    if (tempLink !== link) setLink(tempLink);
  }, [link, selectedNFT.source, selectedNFT.contract_address.raw, selectedNFT.token_id_or_nifty_type.raw]);

  useEffect(() => {
    console.log('ue1')
    const tempCreatorSetting = feedSettings.creator.find(item => item.label === creatorName);
    const tempSeriesSetting = feedSettings.series.find(item => item.label === seriesName);
    const tempSourceSetting = feedSettings.source.find(item => item.label === sourceName);

    // Check whether filter was added or removed
    if (!tempCreatorSetting && creatorSetting) setCreatorSetting(false);
    else if (tempCreatorSetting && !creatorSetting) setCreatorSetting(true);
    if (!tempSeriesSetting && seriesSetting) setSeriesSetting(false);
    else if (tempSeriesSetting && !seriesSetting) setSeriesSetting(true);
    if (!tempSourceSetting && sourceSetting) setSourceSetting(false);
    else if (tempSourceSetting && !sourceSetting) setSourceSetting(true);

    // Check whether filter value was changed
    if (creatorSetting) {
      if (creatorSetting.value && !isCreatorOn) setIsCreatorOn(true);
      else if (!creatorSetting.value && isCreatorOn) setIsCreatorOn(false)
    }
    if (seriesSetting) {
      if (seriesSetting.value && !isSeriesOn) setIsSeriesOn(true);
      else if (!seriesSetting.value && isSeriesOn) setIsSeriesOn(false)
    }
    if (sourceSetting) {
      if (sourceSetting.value && !isSourceOn) setIsSourceOn(true);
      else if (!sourceSetting.value && isSourceOn) setIsSourceOn(false)
    }
  }, [feedSettings, creatorName, creatorSetting, isCreatorOn, seriesName, seriesSetting, isSeriesOn, sourceName, sourceSetting, isSourceOn]);

  useEffect(() => {
    console.log('ue2')
    if (!selectedNFT && creatorSetting) setCreatorSetting();
    else if (selectedNFT && !creatorSetting) {
      setCreatorName(selectedNFT?.creator_name?.raw);
      setCreatorSetting(feedSettings.creator.find(item => item.label === creatorName));
      setIsCreatorOn(!creatorSetting || creatorSetting.value);
    }
  }, [selectedNFT, creatorName, creatorSetting, feedSettings]);

  useEffect(() => {
    console.log('ue3')
    if (!selectedNFT && seriesSetting) setSeriesSetting();
    else if (selectedNFT && !seriesSetting) {
      setSeriesName(selectedNFT?.project_name?.raw);
      setSeriesSetting(feedSettings.series.find(item => item.label === seriesName));
      setIsSeriesOn(!seriesSetting || seriesSetting.value);
      setSeriesDisplayName(seriesName.split(' by ')[0]);
    }
  }, [selectedNFT, seriesName, seriesSetting, feedSettings]);

  useEffect(() => {
    console.log('ue4')
    if (!selectedNFT && sourceSetting) setSourceSetting();
    else if (selectedNFT && !sourceSetting) {
      setSourceName(selectedNFT?.source);
      setSourceSetting(feedSettings.source.find(item => item.label === sourceName));
      setIsSourceOn(!sourceSetting || sourceSetting.value);
    }
  }, [selectedNFT, sourceName, sourceSetting, feedSettings]);

  useEffect(() => {
    console.log('ue5')
    const tempHasFilters = [...feedSettings.creator, ...feedSettings.series, ...feedSettings.source].length;

    if (tempHasFilters && !hasFilters) setHasFilters(true);
    else if (!tempHasFilters && hasFilters) setHasFilters(false);
  }, [feedSettings, feedSettings.creator, feedSettings.series, feedSettings.source, hasFilters]);

  return (
    <div className={classnames('selected-nft', { hide })}>
      <header><h3>{hasFilters? 'Adjust' : 'Add'} Feed Filters</h3></header>
      <div className="filters">
        {creatorSetting && (
          <Filter
            onRemove={() => removeFeedFilter('creator', creatorName)}
            isOn={isCreatorOn}
            displayName={creatorName}
            onUpdate={() => updateFeedFilter('creator', creatorName, !creatorSetting.value)}
          />
        )}
        {seriesSetting && (
          <Filter
            onRemove={() => removeFeedFilter('series', seriesName)}
            isOn={isSeriesOn}
            displayName={seriesDisplayName}
            onUpdate={() => updateFeedFilter('series', seriesName, !seriesSetting.value)}
          />
        )}
        {sourceSetting && (
          <Filter
            onRemove={() => removeFeedFilter('source', sourceName)}
            isOn={isSourceOn}
            displayName={sourceName}
            onUpdate={() => updateFeedFilter('source', sourceName, !sourceSetting.value)}
          />
        )}
      </div>

      {hasFilters && <h4>Add new filters</h4>}
      <div className="filter-options">
        {!creatorSetting && (
          <div className="filter-option" onClick={() => addFeedFilter('creator', creatorName)}>
            <span>{creatorName}</span>
            <button className="material-icons">add</button>
          </div>
        )}
        {!seriesSetting && (
          <div className="filter-option" onClick={() => addFeedFilter('series', seriesName)}>
            <span>{seriesDisplayName}</span>
            <button className="material-icons">add</button>
          </div>
        )}
        {!sourceSetting && (
          <div className="filter-option" onClick={() => addFeedFilter('source', sourceName)}>
            <span>{sourceName}</span>
            <button className="material-icons">add</button>
          </div>
        )}
      </div>

      <a className="external-link" href={link} target="_blank" rel="noopener noreferrer">Visit site</a>
      <button className="view-all-filters" onClick={() => setShowAllSettings(true)}>All Settings</button>
    </div>
  );
}

export default NFTFilters;