import React, { useCallback, useEffect, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import classnames from 'classnames';
import { isNumber, values, xor } from 'lodash';
import moment from 'moment';
import AsyncButton from 'react-async-button';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';

import { excerciseIconMap } from './utils';

import Explore from './views/Explore.js';
import PreviousScoreboard from './views/PrevScoreboard';
import Console from './views/Console.js';

import './styles.scss';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function CrewChallenge() {
  const redirectUri = 'https://nataliebeaumont.com/crew-challenge';

  const strava_client_id = '46921';
  const fitbit_client_id = '22BQ4J';

  const authRedirectUrlStrava = `https://www.strava.com/oauth/authorize?client_id=${strava_client_id}&redirect_uri=${redirectUri}&scope=activity:read_all&response_type=code`;
  const authRedirectUrlFitbit = `https://www.fitbit.com/oauth2/authorize?response_type=code&client&client_id=${fitbit_client_id}&response_type=code&scope=profile%20activity&redirect_uri=${redirectUri}`;
  const authUserUrlStrava = 'https://www.strava.com/api/v3/oauth/token';
  const authUserUrlFitbit = 'https://api.fitbit.com/oauth2/token';
  const stravaAthleteUrl = 'https://www.strava.com/api/v3/athlete';
  const fitbitProfileUrl = 'https://api.fitbit.com/1/user/-/profile.json';
  const stravaActivitiesUrl = 'https://www.strava.com/api/v3/athlete/activities?after=1588291201';
  //const fitbitActivitiesUrl = uid => (`https://api.fitbit.com/1/user/${uid}/activities/date/2020-05-02.json`);
  const isFitbitEnabled = false; // TODO: get this from the server

  const firebaseConfig = {
    apiKey: "AIzaSyC7s4Z8I-Gsm3VGK2mjXA3v3gBnue3-hP8",
    authDomain: "crew-challenge.firebaseapp.com",
    databaseURL: "https://crew-challenge.firebaseio.com",
    projectId: "crew-challenge",
    storageBucket: "crew-challenge.appspot.com",
    messagingSenderId: "679290514764",
    appId: "1:679290514764:web:1ebf27c9d354cff0fc33e9",
    measurementId: "G-E7PPMWLX9Q"
  }

  let firebaseApp = firebase.apps && firebase.apps.find(app => app.name === 'crew-challenge');

  if (!firebaseApp) firebaseApp = firebase.initializeApp(firebaseConfig, 'crew-challenge');

  const db = firebaseApp.firestore();

  const defaultSettings = {
    includeOnScoreboard: true,
    showActivities: true,
  };

  const masterSettings = [
    {
      label: 'Show activities list',
      name: 'showActivities',
      defaultSetting: true,
      description: {
        off: 'Your individual activities aren\'t shown.',
        on: 'Your individual activities are listed on the scroreboard.'
      }
    },
    {
      label: 'Include me on the scoreboard',
      name: 'includeOnScoreboard',
      defaultSetting: true,
      description: {
        off: 'Only you can see your entry on the scoreboard.',
        on: 'Everyone can see your entry on the scoreboard.'
      }
    },
  ];

  const classes = useStyles();
  const localUserId = localStorage.getItem('userId');
  const localPwAccepted = localStorage.getItem('challengePwAccepted');
  const localLoginType = localStorage.getItem('loginType');

  const [isFetchingAthleteActivities, setIsFetchingAthleteActivities] = useState(false);
  const [isPasswordAccepted, setIsPasswordAccepted] = useState(localPwAccepted);
  const [isBadPw, setIsBadPw] = useState(false);
  const [userId, setUserId] = useState(localUserId);
  const [accessCode, setAccessCode] = useState();
  const [refreshCode, setRefreshCode] = useState();
  const [athleteInfo, setAthleteInfo] = useState();
  const [athleteActivities, setAthleteActivities] = useState();
  const [athleteActivitiesPrev, setAthleteActivitiesPrev] = useState();
  const [allActivities, setAllActivities] = useState();
  // const [allPrevActivities, setAllPrevActivities] = useState();
  const [needNewAccessCode, setNeedNewAccessCode] = useState(false);
  const [isActivitiesCalculated, setIsActivitiesCalculated] = useState(false);
  const [isPrevActivitiesCalculated, setIsPrevActivitiesCalculated] = useState(false);
  const [isScoreboardLoading, setIsScoreboardLoading] = useState(false);
  const [isPrevScoreboardLoading, setIsPrevScoreboardLoading] = useState(false);
  const [scoreboard, setScoreboard] = useState();
  const [prevScoreboard, setPrevScoreboard] = useState();
  const [passwordEntry, setPasswordEntry] = useState('');
  const [loginType, setLoginType] = useState(localLoginType);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isGettingActivities, setIsGettingActivities] = useState(false);
  const [isGettingPrevActivities, setIsGettingPrevActivities] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isExploreOpen, setIsExploreOpen] = useState(false);
  const [isPrevScoreboardOpen, setIsPrevScoreboardOpen] = useState(false);
  const [settings, setSettings] = useState(defaultSettings);
  const [isGettingSettings, setIsGettingSettings] = useState(false);
  const [allSettings, setAllSettings] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isConsoleOpen, setIsConsoleOpen] = useState(false);

  const handlePasswordEntry = entry => {
    setPasswordEntry(entry.target.value);
    setIsBadPw(false);
  }

  const clearAuthSettings = () => {
    setRefreshCode();
    setUserId();
    setAthleteInfo();
    localStorage.removeItem('userId');
    setNeedNewAccessCode(false);
  }

  const redirectToAuth = (type) => {
    const authRedirectUrl = {
      strava: authRedirectUrlStrava,
      fitbit: authRedirectUrlFitbit,
    }[type];

    clearAuthSettings();
    window.location = authRedirectUrl;
  }

  const handleNewAccessCode = useCallback(({ access_token, athlete, user_id, refresh_token }) => {
    // Update the database with the new refresh code and access code
    // Set or update the users refresh code
    const newUserId = athlete?.id || user_id;

    db.collection('refreshCodes').doc(`${newUserId}`).set({
      userId: newUserId,
      access_token,
      refresh_token,
      updated: Date.now(),
      type: loginType
    }, {
      merge: true,
    })
    .catch(error => {
      console.error('Unable to set codes', error );

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Setting refresh codes',
        error
      });
    });
  }, [db, loginType, userId]);

  const authenticateFitbitUser = useCallback(async (token, isRefresh) => {
    const headers = new Headers();
    const urlencoded = new URLSearchParams();

    if (!token) setNeedNewAccessCode(true);

    headers.append("Authorization", "Basic MjJCUTRKOmQzZTY0ZDMwNWE1YzYyZmQwYzU0ZmVmMzgzNGMxZDNl");
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append("code", token);
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("client_id", fitbit_client_id);
    urlencoded.append("redirect_uri", "https://nataliebeaumont.com/crew-challenge");

    const userResponse = await fetch(authUserUrlFitbit, {
      body: urlencoded,
      headers,
      method: 'POST'
    }).then(response => response.json())
    .catch(error => console.error('error', error));

    if (userResponse?.errors?.length) {
      if (isRefresh) {
        clearAuthSettings();
        setNeedNewAccessCode(true);
      }

    } else if(userResponse?.access_token) {
      handleNewAccessCode(userResponse);
      setAccessCode(userResponse.access_token);
      setUserId(`${userResponse.user_id}`);

      if (localStorage.getItem('userId') !== `${userResponse.user_id}`) localStorage.setItem('userId', userResponse.user_id);
    }

    clearUrlParams();
  }, [handleNewAccessCode]);

  const authenticateStravaUser = useCallback(async (token, isRefresh) => {
    const headers = new Headers();
    const urlencoded = new URLSearchParams();

    if (!token) {
      if (isRefresh) return setNeedNewAccessCode(true);
      else {
        if (refreshCode) return authenticateStravaUser(refreshCode, true);
        else setNeedNewAccessCode(true);
      }
    }

    headers.append("Content-Type", "application/x-www-form-urlencoded");
    urlencoded.append("client_id", strava_client_id);
    urlencoded.append("client_secret", "9539effea95cab84cc3df7c1140ebbd8fa946bfb");
    urlencoded.append("grant_type", "authorization_code");

    if (isRefresh) urlencoded.append("refresh_code", token);
    else urlencoded.append("code", token);
    
    const userResponse = await fetch(authUserUrlStrava, {
      body: urlencoded,
      headers,
      method: 'POST'
    }).then(response => response.json())
    .catch(error => console.error('error', error));

    if (userResponse?.errors?.length) {
      if (isRefresh) {
        clearAuthSettings();
        setNeedNewAccessCode(true);
      }
    } else if(userResponse?.athlete?.id) {
      handleNewAccessCode(userResponse);
      setAccessCode(userResponse.access_token);
      setUserId(`${userResponse.athlete.id}`);

      if (localStorage.getItem('userId') !== `${userResponse.athlete.id}`) localStorage.setItem('userId', userResponse.athlete.id);
    }

    clearUrlParams();
  }, [handleNewAccessCode, refreshCode]);

  const toggleAthlete = (athleteId) => {
    const tempScoreboard = [ ...scoreboard ];
    const selectedAthleteIndex = tempScoreboard.findIndex(item => item.userId === athleteId);

    if (selectedAthleteIndex < 0) return;

    tempScoreboard[selectedAthleteIndex].isOpen = !tempScoreboard[selectedAthleteIndex].isOpen;

    setScoreboard(tempScoreboard);
  }

  const getScoreboardView = board => {
    const countedEntries = [];
    const anonymousEntries = [];

    board.forEach(entry => {
      if (entry.settings?.includeOnScoreboard && entry.totalDistance) countedEntries.push(entry);
      else if (!entry.settings?.includeOnScoreboard) anonymousEntries.push(entry);
    });

    const allMiles = countedEntries.reduce((a, b) => a + +b.totalDistance, 0);

    return (
      <div className='scoreboard-container'>
        {!board || !board[0].settings
          ? 'Loading scoreboard...'
          : (
              <>
                <div
                  className={classnames('entry-container', {
                    refreshing: board && isScoreboardLoading,
                  })}
                >
                  {
                    board.map(({
                      activities,
                      firstName,
                      isOpen,
                      lastName,
                      settings: {
                        includeOnScoreboard,
                        showActivities
                      },
                      totalDistance: distance,
                      userId: entryId,
                    }, i) => {
                      if (!distance) return undefined;

                      return (
                        <Card className={classnames(classes.root, 'scoreboard-entry', {
                          open: isOpen,
                          self: userId === entryId,
                          show: includeOnScoreboard,
                        })} key={entryId}>
                          <CardContent onClick={() => toggleAthlete(entryId)} className="card-contents">
                            <div className="scoreboard-name">
                              <span className={classnames('content', {
                                self: userId === entryId,
                              })}>{`${firstName} ${lastName}`}</span>
                              <span>{userId === entryId ? '(You)' : ''}</span>
                              {+distance >= 100 && <img className="crown" src="/assets/crew/crown-sm.png" alt="Crew crown" />}
                              <span className="material-icons">expand_more</span>
                            </div>
                            <div className="distance">{`${(+distance).toFixed(2)} miles`}</div>
                          </CardContent>
                          <CardContent className="activities">
                            {Boolean(activities) && (
                                <div className="activities-header">
                                  <h3>{activities?.length} <span>{(activities?.length || 0) === 1 ? 'activity' : 'activities'}</span></h3>
                                </div>
                              )
                            }
          
                            {!activities && (
                              <p>{`Activities will be added once ${firstName} logs in.`}</p>
                            )}
  
                            {activities && !showActivities && (
                              <p>{`${firstName} has chosen not to display individual activites.`}</p>
                            )}
          
                            {showActivities && (
                              <div className="activity-list">
                                {activities?.map(({ id, exerciseType, distance, startDate }) => (
                                  <div className="activity" key={id}>
                                    <span className="activity-icon material-icons">{excerciseIconMap(exerciseType)}</span>
                                    <span className="activity-date">{moment(startDate).utc().format('DD MMM')}</span>
                                    <span className="activity-distance">{(+distance).toFixed(2)} mi</span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </CardContent>
                        </Card>
                      )
                    })
                  }
                </div>

                <div className="bottom-container">
                  {moment().format('MMMM') === 'June' && (
                    <Button className="view-prev" onClick={() => setIsPrevScoreboardOpen(true)}>
                      View {moment().subtract(1, 'month').format('MMMM')}
                    </Button>
                   )
                  }

                  <div className={classnames('total', {
                    hasbutton: moment().format('MMMM') === 'June'
                  })}>
                    <p>Together, the {countedEntries.length} of us have gone {allMiles.toFixed(0)} miles.</p>
                    {Boolean(anonymousEntries.length) && <p>(Not including {anonymousEntries.length} anonymous {anonymousEntries.length === 1 ? 'entry' : 'entries'})</p>}
                  </div>
                </div>
              </>
            )
        }
      </div>
    );
  }

  const getTrackerInfo = type => (
    <div className="tracker-info">
      <p>
        <span>Fitbit</span>
        <span>Go <a href="https://strava.fitbit.com/" target="_blank" rel="noopener noreferrer">here</a> to connect your fitbit data to Strava.</span>
      </p>
      <p>
        <span>Apple Health</span>
        <span>Link Apple Health to your Strava account using <a href="https://support.strava.com/hc/en-us/articles/216917527-Health-App-and-Strava" target="_blank" rel="noopener noreferrer">this guide</a>.</span>
      </p>
      <p>
        <span>Google Fit</span>
        <span>Open the Strava app, then go to your profile and click Settings. Then click Link Other Services. You should see an option to link Google Fit, if it's installed on the phone you're using.</span>
      </p>
      <p>
        <span>Nike Club</span>
        <span>Unfortunately, Nike Club data cannot be exported to other apps. You'll have to input your Run Club activities into Strava manually in order to see them here.</span>
      </p>
      <p>
        <span>Other Trackers</span>
        <span>Strava can connect to most trackers and apps. See all of the supported trackers <a href="https://www.strava.com/apps" target="_blank" rel="noopener noreferrer">here</a>.</span>
      </p>
    </div>
  )

  const closeInfoModal = event => {
    event.stopPropagation();

    setIsInfoModalOpen(false);
  }

  const openExplore = () => {
    setIsExploreOpen(true);
  }

  const openConsole = () => {
    if (userId === '31626327') setIsConsoleOpen(true);
  }

  const getAllSettings = useCallback(async () => {
    setIsGettingSettings(true);

    const allSettingsTemp = await db.collection('settings').get()
    .then(settingsRes => {
      if (!settingsRes.empty) {
        return settingsRes.docs.map(item => item.data());
      } else return [];
    })
    .catch(error => {
      console.error('Unable to get all settings');

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Getting all settings',
        error
      });
    });

    setAllSettings([...allSettingsTemp]);
    setIsGettingSettings(false);
  }, [db, userId]);

  const openSettings = () => {
    setIsSettingsOpen(true);
  }

  const changeSettings = async (type) => {
    const tempSettings = settings;

    tempSettings[type] = !settings[type];

    await db.collection('settings').doc(userId).set(tempSettings)
    .catch(error => {
      console.error('Unable to change user settings:', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Changing user settings',
        error
      });
    });

    setSettings(tempSettings);
    getAllSettings();
  }

  const deleteEntry = async (id) => {
    localStorage.removeItem('challengePwAccepted');
    localStorage.removeItem('loginType');

    await db.collection('refreshCodes').doc(userId).delete();
    await db.collection('activities').doc(userId).delete();
    await db.collection('activitiesPrev').doc(userId).delete();
    await db.collection('athleteDistance').doc(userId).delete();
    await db.collection('athleteDistancePrev').doc(userId).delete();

    clearAuthSettings();

    return db.collection('settings').doc(userId).delete();
  }

  const getLoggedInView = board => (
    <div className="logged-in-container">
      <div className="greeting">
        <p
          onClick={() => getScoreboard(true)}
          className={classnames('refresh', 'material-icons', {
            spin: isScoreboardLoading,
          })}
        >
          refresh
        </p>

        <p>Trying to get 100 miles in {moment().format('MMMM')}</p>

        <div className="section-buttons">
          <p
            onClick={openExplore}
            className={`explore material-icons`}
          >
            pie_chart
          </p>

          <p
            onClick={openSettings}
            className={`settings material-icons`}
          >
            settings
          </p>
        </div>
      </div>

      {isScoreboardLoading && !board
        ? 'Loading scoreboard'
        : getScoreboardView(board)
      }

      <div className={classnames('settings-container', {
        show: isSettingsOpen
      })}>
        <header>
          <h3>Settings</h3>
          <span className="material-icons close" onClick={() => setIsSettingsOpen(false)}>close</span>
        </header>

        <div className="settings-list">
          {masterSettings.map(({ label, name, defaultSetting, description }) => (
            <div className="setting" key={name}>
              <div>
                <div className="setting-label">{label}</div>
                <span className="description">
                  {(typeof settings[name] === 'boolean' ? settings[name] : defaultSetting)
                    ? description.on
                    : description.off
                  }
                </span>
              </div>

              <Switch
                checked={settings[name]}
                onChange={() => changeSettings(name)}
                color="primary"
                name={label}
                inputProps={{ 'aria-label': label }}
              />
            </div>
          ))}

          <button className="delete-button" onClick={() => setShowDeleteConfirmation(true)}>Delete me</button>
        </div>

        <div className={classnames('confirm-delete-container', {
            show: showDeleteConfirmation,
          })}>
          <div className="confirm-delete">
            <p>Are you sure you want to delete your entry from the board?</p>
            <p>You'll be logged out. Logging back in at any future time will add your entry back to the board.</p>

            <div className="button-container">
              <button className="cancel" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
              <AsyncButton
                className="confirm"
                text="Delete"
                pendingText="Deleting..."
                onClick={() => deleteEntry(userId)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classnames('explore-container', {
        show: isExploreOpen,
      })}>
        <header>
          <h3>Data Exploration</h3>
          <span className="material-icons close" onClick={() => setIsExploreOpen(false)}>close</span>
        </header>

        {allActivities && <Explore activities={allActivities} />}
      </div>

      <div className={classnames('prev-scoreboard-container', {
        show: isPrevScoreboardOpen,
      })}>
        <header>
          <h3>Previous Month</h3>
          <span className="material-icons close" onClick={() => setIsPrevScoreboardOpen(false)}>close</span>
        </header>

        <PreviousScoreboard userId={userId} board={prevScoreboard} isScoreboardLoading={isPrevScoreboardLoading} />
      </div>

      <div className={classnames('console-container', {
          show: isConsoleOpen && userId === '31626327',
        })}
      >
        <header>
          <h3>Console</h3>
          <span className="material-icons close" onClick={() => setIsConsoleOpen(false)}>close</span>
        </header>

        <Console athletes={allActivities} />
      </div>
    </div>
  );

  const handleLoginClick = type => {
    localStorage.setItem('loginType', type);
    setLoginType(type);
    redirectToAuth(type);
  }

  const getNoAthleteView = () => {
    if (!needNewAccessCode && userId && !athleteInfo) {
      return 'Loading...';
    }

    return (
      <div className="no-athlete">
        {userId
          ? <p>Welcome back! Log in again to see the scoreboard.</p>
          : (
            <>
              <p>Welcome! To see the scoreboard, use the button below to log in with Strava.</p>
              <p>If you don't have a Strava account, click <a href="https://www.strava.com/register" target="_blank" rel="noopener noreferrer">here</a> to create one. Then come back and log in.</p>
            </>
          )
        }

        <div className="login-buttons">
          {loginType !== 'fitbit' && (
            <Button className="strava-login" onClick={() => handleLoginClick('strava')}>
              Log in with Strava
            </Button>
          )}
          {isFitbitEnabled && loginType !== 'strava' && (
            <Button className="fitbit-login" onClick={() => handleLoginClick('fitbit')}>
              Log in with Fitbit
            </Button>
          )}
        </div>

        <span onClick={() => setIsInfoModalOpen(true)} className="info-button">Why Strava?</span>
      </div>
    )
  }

  const getStoredCodes = useCallback(async () => {
    const storedCodes = await db.collection('refreshCodes').doc(userId).get()
    .then(response => {
      if (response.exists) {
        return response.data()
      } else {
        setNeedNewAccessCode(true);
      }
    })
    .catch(error => {
      console.error('Unable to get codes', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Getting stored codes',
        error
      });
    });

    setRefreshCode(storedCodes?.refresh_token);
    setAccessCode(storedCodes?.access_token);
  }, [db, userId]);

  const getFitbitProfileInfo = useCallback(async (token) => {
    if (!token) return getStoredCodes();

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const profileResponse = await fetch(fitbitProfileUrl, {
      headers
    }).then(response => response.json());

    if (profileResponse.errors?.length) {
      setAccessCode();

      // Attemps to refresh the token
      authenticateFitbitUser(refreshCode, true);
    } else {
      const athleteInfo = {
        firstname: profileResponse.user.firstName,
        lastname: profileResponse.user.lastName,
      }

      setAthleteInfo(athleteInfo);
    }
  }, [authenticateFitbitUser, getStoredCodes, refreshCode]);

  const getStravaAthleteInfo = useCallback(async (token) => {
    if (!token) return getStoredCodes();
    
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const athleteResponse = await fetch(stravaAthleteUrl, {
      headers
    }).then(response => response.json());

    if (athleteResponse.errors?.length) {
      setAccessCode();

      // Attemps to refresh the token
      authenticateStravaUser(refreshCode, true);
    } else {
      setAthleteInfo(athleteResponse);
    }
  }, [authenticateStravaUser, getStoredCodes, refreshCode]);

  const getAthleteInfo = useCallback((type) => {
    if (needNewAccessCode) return;

    if (type === 'strava') getStravaAthleteInfo(accessCode);
    else if (type === 'fitbit') getFitbitProfileInfo(accessCode); 
  }, [accessCode, getFitbitProfileInfo, getStravaAthleteInfo, needNewAccessCode]);

  const clearUrlParams = () => {
    const clean_uri = window.location.protocol + "//" + window.location.host + window.location.pathname;

    window.history.replaceState({}, document.title, clean_uri);
  }

  const createNewSettings = useCallback(async () => {
    await db.collection('settings').doc(userId).set({
      ...defaultSettings,
      userId,
    })
    .catch(error => {
      console.error('Unable to create new settings', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Creating new settings',
        error
      });
    });

    getAllSettings();
  }, [db, defaultSettings, getAllSettings, userId]);

  const loadSettingsFromDB = useCallback(async () => {
    setIsGettingSettings(true);

    const settingsResponse = await db.collection('settings').doc(userId).get()
    .then(response => response.exists && response.data())
    .catch(error => {
      console.error('Unable to load settings', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Loading settings from DB',
        error
      });
    });

    if (settingsResponse) setSettings(settingsResponse);
    else createNewSettings();
  }, [createNewSettings, db, userId]);

  const loadActivitiesFromDB = useCallback(async (board) => {
    setIsGettingActivities(true);

    const tempScoreboard = [ ...board ];

    const activitiesResponse = await db.collection('activities').get()
    .then(rawActivities => rawActivities.docs.map(item => item.data()))
    .catch(error => {
      console.error('Unable to load activities from db', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Loading all activities from DB',
        error
      });
    });

    const activitiesHardcodedResponse = await db.collection('activities-hardcoded').get()
    .then(rawActivities => rawActivities.docs.map(item => item.data()))
    .catch(error => console.error('Unable to load hardcoded activities from db', error));

    activitiesResponse.forEach(response => {
      const athleteIndex = tempScoreboard.findIndex(item => item.userId === response.userId);
      const hardcodedActivitiesItem = activitiesHardcodedResponse.find(item => item.userId === response.userId);
      const hardcodedActivities = hardcodedActivitiesItem ? hardcodedActivitiesItem.activities : [];
      const sortedActivities = [ ...response.activities, ...hardcodedActivities ].sort((a, b) => {
        return moment(a.startDate).isBefore(b.startDate);
      });

      if (tempScoreboard[athleteIndex]) tempScoreboard[athleteIndex].activities = sortedActivities;
    });

    const allActivitiesTemp = activitiesResponse.map(item => {
      const { firstName, lastName, userId } = board.find(b => b.userId === item.userId);

      return {
        ...item,
        profile: {
          firstName,
          lastName,
          userId,
        }
      }
    })

    setAllActivities([...allActivitiesTemp]);
    setScoreboard([ ...tempScoreboard ]);
    setIsGettingActivities(false);
  }, [db, userId]);

  const loadPreviousMonthActivitiesFromDB = useCallback(async (board) => {
    setIsGettingPrevActivities(true);

    const tempPrevScoreboard = [ ...board ];

    const activitiesPrevResponse = await db.collection('activitiesPrev').get()
    .then(rawActivities => rawActivities.docs.map(item => item.data()))
    .catch(error => {
      console.error('Unable to load previous month\'s activities from db', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Loading all previous activities from DB',
        error
      });
    });

    activitiesPrevResponse.forEach(response => {
      const athleteIndex = tempPrevScoreboard.findIndex(item => item.userId === response.userId);
      const sortedActivities = [ ...response.activities ].sort((a, b) => {
        return moment(a.startDate).isBefore(b.startDate);
      });

      if (tempPrevScoreboard[athleteIndex]) tempPrevScoreboard[athleteIndex].activities = sortedActivities;
    });

    // const allPrevActivitiesTemp = activitiesPrevResponse.map(item => {
    //   const { firstName, lastName, userId } = board.find(b => b.userId === item.userId);

    //   return {
    //     ...item,
    //     profile: {
    //       firstName,
    //       lastName,
    //       userId,
    //     }
    //   }
    // })

    //setAllPrevActivities([...allPrevActivitiesTemp]);
    setPrevScoreboard([ ...tempPrevScoreboard ]);
    setIsGettingPrevActivities(false);
  }, [db, userId]);

  const getAthleteActivities = useCallback(async (token, page = 1, pages = []) => {
    setIsFetchingAthleteActivities(true);
    const activitiesUrl = stravaActivitiesUrl;
    const headers = new Headers();

    headers.append('Authorization', `Bearer ${token}`);
    headers.append("Accept-Language", "en_US");

    const activitiesResponse = await fetch(`${activitiesUrl}&page=${page}&per_page=30`, {
      headers,
    }).then(response => response.json());

    if (activitiesResponse.errors?.length) {
      if (activitiesResponse.errors.some(item => ['invalid', 'expired'].includes(item.code))) {
        setAccessCode();

        // Attempts to refresh the token
        authenticateStravaUser(refreshCode, true);
      }
    } else {
      if (activitiesResponse.length >= 30) getAthleteActivities(token, page + 1, [...pages, ...activitiesResponse]);
      else {
        const athleteActivitiesTemp = [];
        const athleteActivitiesPrevTemp = [];

        [...pages, ...activitiesResponse].forEach(item => {
          if (
            moment(item.start_date_local).isAfter(moment().subtract(1, 'month').startOf('month'))
            && moment(item.start_date_local).isBefore(moment().startOf('month'))
          ) {
            athleteActivitiesPrevTemp.push(item);
          }
          else if (moment(item.start_date_local).isAfter(moment().startOf('month'))) {
            athleteActivitiesTemp.push(item);
          }
        })

        setAthleteActivities(athleteActivitiesTemp);
        setAthleteActivitiesPrev(athleteActivitiesPrevTemp);
        setIsFetchingAthleteActivities(false);
      }
    }
  }, [authenticateStravaUser, refreshCode]);

  const updateTotalDistance = useCallback(async totalDistance => {
    if (!userId || !isNumber(totalDistance)) return;

    await db.collection('athleteDistance').doc(userId).set({
      userId,
      totalDistance,
      firstName: athleteInfo.firstname,
      lastName: athleteInfo.lastname,
    }, {
      merge: true,
    })
    .catch(error => {
      console.error('Unable to update user', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Updating athleteDistance',
        error
      });
    });
  }, [athleteInfo.firstname, athleteInfo.lastname, db, userId]);

  const updateTotalPrevDistance = useCallback(async totalPrevDistance => {
    if (!userId || !isNumber(totalPrevDistance)) return;

    await db.collection('athleteDistancePrev').doc(userId).set({
      userId,
      totalPrevDistance,
      firstName: athleteInfo.firstname,
      lastName: athleteInfo.lastname,
    }, {
      merge: true,
    })
    .catch(error => {
      console.error('Unable to update user prev', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Updating athleteDistancePrev',
        error
      });
    });
  }, [athleteInfo.firstname, athleteInfo.lastname, db, userId]);

  const exerciseTypeMap = type => (
    {
      hike: 'hike',
      ride: 'bicycle',
      rowing: 'rowing',
      run: 'run',
      swim: 'swim',
      walk: 'walk'
    }[type.toLowerCase()]
  ) || type.toLowerCase();

  const getModalContent = isLoggedIn => {
    if (isLoggedIn) return (
      <>
        <div className="info-item">
          <label>How do I add activities?</label>
          <p>Your activities from {loginType} will show up here automatically. All you have to do is refresh the page!</p>
        </div>
        <div className="info-item">
          <label>What about activities from different trackers?</label>
          {getTrackerInfo(loginType)}
        </div>
      </>
    );

    return (
      <>
        <div className="info-item">
          <label>Why Strava?</label>
          <p>Strava is able to integrate with a <a href="https://www.strava.com/apps" target="_blank" rel="noopener noreferrer">wide variety</a> of other apps and trackers. That means you can track things like your <strong>Fitbit</strong> and <strong>Peleton</strong> activities in one place.</p>
        </div>
      </>
    )
  }

  const calculateActivities = useCallback(async (activities, board) => {
    const activitiesForDB = [];
    const totalMeters = activities.reduce((a, b) => {
      if (moment(b.start_date_local).isBefore(moment().startOf('month'))) return a;

      if (+b.distance > 0) {
        activitiesForDB.push({
          distance: b.distance * 0.000621371192,
          elapsedTime: b.elapsed_time,
          exerciseType: exerciseTypeMap(b.type),
          externalId: b.external_id,
          id: b.id,
          service: 'strava',
          startDate: b.start_date_local,
        });
      }

      return a + +b.distance;
    }, 0);

    updateTotalDistance(totalMeters * 0.000621371192);
    setIsActivitiesCalculated(true);

    await db.collection('activities').doc(userId).set({
      activities: activitiesForDB,
      userId,
    })
    .then(() => {
      if (!isGettingSettings) loadSettingsFromDB();
      if (!isGettingActivities && board) loadActivitiesFromDB(board);
    })
    .catch(error => {
      console.error('Unable to update activities', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Updating activities in db',
        error
      });
    });
  }, [db, isGettingActivities, isGettingSettings, loadActivitiesFromDB, loadSettingsFromDB, updateTotalDistance, userId]);

  const calculatePreviousMonthActivities = useCallback(async (activities, board) => {
    const activitiesForDB = [];

    const totalMeters = activities.reduce((a, b) => {
      if (
        moment(b.start_date_local).isBefore(moment().subtract(1, 'month').startOf('month'))
        || moment(b.start_date_local).isAfter(moment().startOf('month'))
      ) return a;

      if (+b.distance > 0) {
        activitiesForDB.push({
          distance: b.distance * 0.000621371192,
          elapsedTime: b.elapsed_time,
          exerciseType: exerciseTypeMap(b.type),
          externalId: b.external_id,
          id: b.id,
          service: 'strava',
          startDate: b.start_date_local,
        });
      }

      return a + +b.distance;
    }, 0);

    updateTotalPrevDistance(totalMeters * 0.000621371192);
    setIsPrevActivitiesCalculated(true);

    await db.collection('activitiesPrev').doc(userId).set({
      activities: activitiesForDB,
      userId,
    })
    .then(() => {
      if (!isGettingPrevActivities && board) loadPreviousMonthActivitiesFromDB(board);
    })
    .catch(error => {
      console.error('Unable to update previous month\'s activities', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Updating previous month\'s activities in db',
        error
      });
    });
  }, [db, isGettingPrevActivities, loadPreviousMonthActivitiesFromDB, updateTotalPrevDistance, userId]);

  const getScoreboard = useCallback((shouldRefreshActivities) => {
    setIsScoreboardLoading(true);

    db.collection('athleteDistance').get()
    .then(scoreboardResponse => {
      if (!scoreboardResponse.isEmpty) {
        const scoreboardData = scoreboardResponse.docs.map(item => item.data());
        scoreboardData.sort((a, b) => b.totalDistance - a.totalDistance)

        setScoreboard(scoreboardData);
        setIsScoreboardLoading(false);
        
        if (shouldRefreshActivities && athleteActivities && allSettings) calculateActivities(athleteActivities, scoreboardData);
      }
    })
    .catch(error => {
      console.error('error getting scores', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Getting scores',
        error
      });
    });
  }, [allSettings, athleteActivities, calculateActivities, db, userId])

  const getPrevScoreboard = useCallback(shouldRefreshActivities => {
    setIsPrevScoreboardLoading(true);

    db.collection('athleteDistancePrev').get()
    .then(scoreboardResponse => {
      if (!scoreboardResponse.isEmpty) {
        const scoreboardData = scoreboardResponse.docs.map(item => item.data());
        scoreboardData.sort((a, b) => b.totalDistance - a.totalDistance)

        setPrevScoreboard(scoreboardData);
        setIsPrevScoreboardLoading(false);
        
        if (shouldRefreshActivities && athleteActivitiesPrev && allSettings) calculatePreviousMonthActivities(athleteActivitiesPrev, scoreboardData);
      }
    })
    .catch(error => {
      console.error('error getting prev scores', error);

      db.collection('errors').doc().set({
        time: Date.now(),
        userId,
        action: 'Getting prev scores',
        error
      });
    });
  }, [allSettings, athleteActivitiesPrev, calculatePreviousMonthActivities, db, userId]);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (passwordEntry.toLowerCase() === 'chauncy') {
      localStorage.setItem('challengePwAccepted', true);
      setIsPasswordAccepted(true);
    }
    else setIsBadPw(true);

    setPasswordEntry('');
  }

  const getPasswordEntryView = () => (
    <div className="password-view">
      <p>Enter the password to access the challenge.</p>

      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleFormSubmit}>
        <TextField id="password" type="text" label="Password" value={passwordEntry} onChange={handlePasswordEntry} />
        <Button onClick={handleFormSubmit} disabled={!passwordEntry.length} variant="contained" color="primary">
          Submit
        </Button>
      </form>

      {isBadPw && <p className="error-message">Wrong password. Please try again.</p>}
    </div>
  );

  const addSettingsToScoreboard = useCallback((allSettings, scoreboard, prevScoreboard) => {
    setIsScoreboardLoading(true);

    const allSettingsTemp = [...allSettings];
    const scoreboardTemp = [...scoreboard];
    const prevScoreboardTemp = [...prevScoreboard];

    scoreboardTemp.forEach(entry => {
      const userSettings = allSettingsTemp.find(item => item.userId === entry.userId);

      entry.settings = userSettings || defaultSettings;
    });

    prevScoreboardTemp.forEach(entry => {
      const userSettings = allSettingsTemp.find(item => item.userId === entry.userId);

      entry.settings = userSettings || defaultSettings;
    });

    setScoreboard([...scoreboardTemp]);
    setPrevScoreboard([...prevScoreboardTemp]);
    setIsScoreboardLoading(false);
  }, [defaultSettings]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlCode = urlParams.get('code');

    if (userId) getStoredCodes();
    else if (!userId) {
      if (urlCode) {
        authenticateStravaUser(urlCode);
      } else {
        setNeedNewAccessCode(true);
      }
    }
  }, [authenticateStravaUser, getStoredCodes, userId]);

  useEffect(() => {
    if (accessCode) {
      if (!athleteInfo) getAthleteInfo('strava');
    }
  }, [accessCode, athleteInfo, getAthleteInfo]);

  useEffect(() => {
    if (accessCode && athleteInfo && !athleteActivities && !isFetchingAthleteActivities) getAthleteActivities(accessCode);
  }, [accessCode, athleteActivities, athleteInfo, getAthleteActivities, isFetchingAthleteActivities]);

  useEffect(() => {
    if (!scoreboard && !isScoreboardLoading) getScoreboard();
    else if (!prevScoreboard && !isPrevScoreboardLoading) getPrevScoreboard();
    else if (scoreboard && !isScoreboardLoading && !allSettings) getAllSettings(scoreboard);
    else if (athleteActivities && !isActivitiesCalculated && scoreboard && !isScoreboardLoading && !isGettingActivities && allSettings) calculateActivities(athleteActivities, scoreboard);
    else if (athleteActivitiesPrev && !isPrevActivitiesCalculated && prevScoreboard && !isPrevScoreboardLoading && !isGettingPrevActivities && allSettings) calculatePreviousMonthActivities(athleteActivitiesPrev, prevScoreboard);
  }, [
    athleteActivities,
    athleteActivitiesPrev,
    calculateActivities,
    calculatePreviousMonthActivities,
    getAllSettings,
    getPrevScoreboard,
    getScoreboard,
    isActivitiesCalculated,
    isGettingActivities,
    isGettingPrevActivities,
    isPrevActivitiesCalculated,
    scoreboard,
    prevScoreboard,
    isPrevScoreboardLoading,
    isScoreboardLoading,
    allSettings
  ]);

  useEffect(() => {
    if (scoreboard && prevScoreboard && allSettings) {
      let isDifferent = false;

      scoreboard.forEach(entry => {
        if (!entry.settings) return isDifferent = true;
        
        const userSettings = allSettings.find(item => item.userId === entry.userId);
        if (userSettings && xor(values(userSettings), values(entry.settings)).length) isDifferent = true;
      });

      if (isDifferent) addSettingsToScoreboard(allSettings, scoreboard, prevScoreboard);
    }
  }, [addSettingsToScoreboard, allSettings, scoreboard, prevScoreboard]);

  return (
    <div className="crew-challenge-container">
      <header className="App-header">
        {userId === '31626327' && (
          <span onClick={openConsole} className="material-icons">keyboard_hide</span>
        )}
        Crew Challenge
        {athleteInfo && !needNewAccessCode && (
          <span onClick={() => setIsInfoModalOpen(true)} className="material-icons">info_outline</span>
        )}
      </header>

      {isPasswordAccepted
        ? (
            <div className="container">
              {athleteInfo && !needNewAccessCode
                ? getLoggedInView(scoreboard)
                : getNoAthleteView()
              }
            </div>
          )
        : getPasswordEntryView()
      }

      <div onClick={closeInfoModal} className={classnames('info-modal-container', {
        open: isInfoModalOpen,
      })}>
        <div className="info-modal" onClick={e => e.stopPropagation()}>
          <span className="material-icons close" onClick={() => setIsInfoModalOpen(false)} >close</span>
          <div className="modal-content">
            {getModalContent(athleteInfo && !needNewAccessCode)}
          </div>
        </div>
      </div>
    </div>
  );
}
