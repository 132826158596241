import React, { Component } from 'react';

import algoliasearch from 'algoliasearch';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { compact, kebabCase } from 'lodash';
import moment from 'moment';

import Updater from '../components/Updater';

import { InputLabel, MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';

import { ALGOLIA_APP_ID, ALGOLIA_API_KEY, firebaseConfig } from '../constants.js';

import './styles.scss';

export default class Items extends Component {
  firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  db = this.firebaseApp.firestore();
  
  algolia = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
  index = this.algolia.initIndex('items');
  
  categories = ['back-blings', 'contrails', 'music', 'emotes', 'gliders', 'loading-screens', 'pickaxes', 'toys', 'skins', 'sprays', 'wraps', 'other'];
  rarities = ['common', 'dark', 'dc', 'uncommon', 'rare', 'epic', 'lava', 'legendary', 'marvel', 'icon', 'shadow', 'starwars'];

  state = {
    itemValues: {
      category: ''
    },
    itemManagerValues: {
      category: '',
      id: '',
      imageUrl: '',
      name: '',
      rarity: ''
    },
    newItemsJSON: '',
    newBulkItemsJSON: '',
    usersJSON: ''
  };

  componentDidUpdate(prevProps, prevState) {
    const { allItems, showItemManager } = this.state;

    if (showItemManager && allItems?.length && !prevState.allItems?.length) this.getNextManagedItem();
  }

  render () {
    const {
      corruptedItems,
      itemValues,
      newItemsJSON,
      newBulkItemsJSON,
      pendingUpdate,
      pendingAlgoliaUpdate,
      pendingBulkUpdate,
      pendingItemUpdate,
      pendingQuickUpdate,
      showItemManager
    } = this.state;

    return (
      <div key="items-container" className="items-container">
        <button className="quick-update cta" onClick={this.quickUpdateItemShop}>
          Quick Update Item Shop {pendingQuickUpdate && <i className="material-icons spinner">autorenew</i>}
        </button>

        <button className="update-algolia cta" onClick={this.updateAllAlgoliaItems}>
          Update all Algolia items {pendingAlgoliaUpdate && <i className="material-icons spinner">autorenew</i>}
        </button>

        {showItemManager
          ? this.getItemManager(corruptedItems)
          : <button className="open-manager cta" onClick={this.openItemManager}>Open Item Manager</button>
        }

        <div className="updaters">
          <Updater
            id="item-shop-updater"
            className="item-shop-updater"
            fields={[
              {
                id: 'newItemsJSON',
                element: TextareaAutosize,
                props: {
                  onChange: event => this.handleChange(event, 'newItemsJSON'),
                  value: newItemsJSON,
                  rows: 6
                }
              }
            ]}
            submitDisabled={!newItemsJSON.length}
            onSubmit={this.updateItemShop}
            submitContent={<>Update Item Shop {pendingUpdate && <i className="material-icons spinner">autorenew</i>}</>}
          />

          <Updater
            id="bulk-item-creater"
            className="bulk-item-creater"
            fields={[
              {
                id: 'newBulkItemsJSON',
                element: TextareaAutosize,
                props: {
                  onChange: event => this.handleChange(event, 'newBulkItemsJSON'),
                  value: newBulkItemsJSON,
                  rows: 6
                }
              }
            ]}
            submitDisabled={!newBulkItemsJSON.length}
            onSubmit={this.bulkUpdateItems}
            submitContent={<>Bulk Add Items {pendingBulkUpdate && <i className="material-icons spinner">autorenew</i>}</>}
          />
        </div>

        <Updater
          id="item-creater"
          className="item-creater"
          fields={[
            {
              id: 'rarity-label',
              element: InputLabel,
              props: {
                htmlFor: "selected-rarity",
                children: "Rarity"
              }
            },
            {
              id: 'rarity',
              element: Select,
              props: {
                value: itemValues.rarity || '',
                inputProps: {
                  name: 'selected-rarity',
                  id: 'selected-rarity'
                },
                onChange: event => this.handleItemChange(event, 'rarity'),
                className: 'input rarity',
                children: this.rarities.map(str => (
                  <MenuItem key={str} value={str}>{str}</MenuItem>
                ))
              }
            },
            {
              id: 'item-name',
              element: TextField,
              props: {
                className: 'input',
                label: 'Name',
                value: itemValues.name,
                onChange: event => this.handleItemChange(event, 'name')
              }
            },
            {
              id: 'item-name-es',
              element: TextField,
              props: {
                className: 'input',
                label: 'Name (Spanish)',
                value: itemValues.nameES,
                onChange: event => this.handleItemChange(event, 'nameES')
              }
            },
            {
              id: 'itemCategory',
              element: Select,
              props: {
                value: itemValues.category || '',
                inputProps: {
                  name: 'selected-item-category',
                  id: 'selected-item-category'
                },
                onChange: event => this.handleItemChange(event, 'category'),
                className: 'input category',
                children: this.categories.map(str => (
                  <MenuItem key={str} value={str}>{str}</MenuItem>
                ))
              }
            },
            {
              id: 'item-price',
              element: TextField,
              props: {
                className: 'input',
                label: 'Price',
                value: itemValues.price,
                onChange: event => this.handleItemChange(event, 'price')
              }
            }
          ]}
          submitDisabled={!itemValues.name || !itemValues.price || !itemValues.rarity || !itemValues.category}
          onSubmit={this.submitItem}
          submitContent={<>Add Item {pendingItemUpdate && <i className="material-icons spinner">autorenew</i>}</>}
        />
      </div>
    );
  }

  openItemManager = async () => {
    this.setState({ itemManagerPending: true, showItemManager: true });

    await this.getAllItems();

    this.getNextManagedItem()
  }

  getAllItems = async () => {
    const { allItems } = this.state;

    if (allItems?.length) return allItems;

    return this.db.collection('item-shop').get().then((rawItems) => {
      const items = rawItems.docs.map(item => item.data());

      this.setState({ allItems: items });

      return items;
    });
  }

  setCorruptedItems = async () => {
    const allItems = await this.getAllItems();

    if (!allItems) return this.setState({ managerMesssage: 'Error receiving items', itemManagerPending: false, selectedItem: undefined });

    const corruptedItems = allItems.filter(item => {
      return (!item.current_items && (!item.category || !item.id || !item.imageUrl || !item.name || !item.rarity
        || !this.rarities.some(rarity => item.rarity.toLowerCase() === rarity)
        || !this.categories.some(category => category === item.category.toLowerCase())
        || !item.imageUrl.includes('.')));
    });

    this.setState({ corruptedItems });
  }

  updateAlgoliaItem = (item) => {
    const itemForAlgolia = { ...item, objectID: item.id };

    this.index.partialUpdateObject(itemForAlgolia, { createIfNotExists: true })
    .catch(err => console.error(`Error updating item: ${err.message}`));
  }

  updateAllAlgoliaItems = async () => {
    this.setState({ pendingAlgoliaUpdate: true });

    const allItems = await this.getAllItems();
    const itemsForAlgolia = compact(allItems.map(item => {
      if (!item.id) return undefined;

      return ({
        ...item,
        objectID: item.id
      })
    }));

    this.index.partialUpdateObjects(itemsForAlgolia, { createIfNotExists: true })
    .then((objectIds) => {
      console.log(`Updated ${objectIds.length} of ${allItems.length} items.`);

      this.setState({ pendingAlgoliaUpdate: false });
    })
    .catch(err => console.error(`Error updating items: ${err.message}`));
  }

  getItemManager = (corruptedItems) => {
    const { itemManagerPending, itemManagerValues, managerMesssage, selectedItem } = this.state;

    return (
      <div className="item-manager">
        <button onClick={() => this.setState({ showItemManager: false })}>Close Item Manager</button>

        {corruptedItems && <h2>{corruptedItems.length} items to review</h2>}

        {itemManagerPending && <p>Loading next item...</p>}

        <ul>
          <li>Quality = Epic</li>
          <li>Fine = Legendary</li>
          <li>Handmade = Uncommon</li>
          <li>Sturdy = Rare</li>
        </ul>

        {selectedItem
          ? <>
              <img alt="selected item" src={itemManagerValues.imageUrl.length ? itemManagerValues.imageUrl : selectedItem.imageUrl} />

              <div className="item-info-box">
                <Select
                  value={itemManagerValues.category.length ? itemManagerValues.category : selectedItem.category || 'Choose Category'}
                  inputProps={{
                    name: 'selected-rarity',
                    id: 'selected-rarity'
                  }}
                  onChange={event => this.handleItemManagerChange(event, 'category')}
                  className={'input category'}
                  children={this.categories.map(str => (
                    <MenuItem key={str} value={str}>{str}</MenuItem>
                  ))}
                />

                <TextField
                  className="input"
                  label={'Name'}
                  value={itemManagerValues.name.length ? itemManagerValues.name : selectedItem.name || ''}
                  onChange={event => this.handleItemManagerChange(event, 'name')}
                />

                <TextField
                  className="input"
                  label={'ID'}
                  value={itemManagerValues.id.length ? itemManagerValues.id : selectedItem.id || ''}
                  onChange={event => this.handleItemManagerChange(event, 'id')}
                />

                <TextField
                  className="input"
                  label={'Rarity'}
                  value={itemManagerValues.rarity.length ? itemManagerValues.rarity : selectedItem.rarity || ''}
                  onChange={event => this.handleItemManagerChange(event, 'rarity')}
                />

                <TextField
                  className="input"
                  label={'Image URL'}
                  value={itemManagerValues.imageUrl.length ? itemManagerValues.imageUrl : selectedItem.imageUrl || ''}
                  onChange={event => this.handleItemManagerChange(event, 'imageUrl')}
                />
              </div>

              <button disabled={
                (!itemManagerValues.id && !selectedItem.id)
                || (!itemManagerValues.category && !selectedItem.category)
              } onClick={this.submitItemManager}>Update Item</button>
            </>
          : !itemManagerPending && <p>{managerMesssage || 'Couldn\'t find any corrupted items.'}</p>
        }
      </div>
    );
  }

  getNextManagedItem = () => {
    const { corruptedItems } = this.state;

    this.clearManagerValues();

    if (!corruptedItems) return this.setCorruptedItems();

    if (!corruptedItems.length) return this.setState({ itemManagerPending: false, selectedItem: undefined });

    const selectedItem = corruptedItems.shift();
    const itemRarity = selectedItem.rarity || selectedItem.rawRarity;

    if (itemRarity) {
      if (itemRarity.toLowerCase() === 'quality' || itemRarity === 'epic') selectedItem.rarity = 'epic';
      else if (itemRarity.toLowerCase() === 'fine' || itemRarity === 'legendary') selectedItem.rarity = 'legendary';
      else if (itemRarity.toLowerCase() === 'handmade' || itemRarity === 'uncommon') selectedItem.rarity = 'uncommon';
      else if (itemRarity.toLowerCase() === 'sturdy' || itemRarity === 'rare') selectedItem.rarity = 'rare';
    }

    if (!selectedItem.id) selectedItem.id = kebabCase(selectedItem.name);

    this.setState({ corruptedItems, itemManagerPending: false, selectedItem });
  }

  clearManagerValues = () => {
    this.setState({
      itemManagerValues: {
        category: '',
        id: '',
        imageUrl: '',
        name: '',
        rarity: ''
      },
      managerMesssage: undefined
    });
  }

  submitItem = () => {
    const { itemValues: {
      category,
      name,
      nameES,
      price,
      rarity
    } } = this.state;
    const itemId = kebabCase(name);

    this.setState({ pendingItemUpdate: true });

    this.db.collection('item-shop').doc(itemId).set({ category, name, nameES, price: +price, rarity }, { merge: true })
    .then(() => {
      this.setState({ pendingItemUpdate: false }, () => this.clearValues());
    });
  }

  submitItemManager = () => {
    const { itemManagerValues, selectedItem } = this.state;
    const updatedItem = {
      category: itemManagerValues.category.length ? itemManagerValues.category : selectedItem.category,
      id: itemManagerValues.id.length ? itemManagerValues.id : selectedItem.id,
      imageUrl: itemManagerValues.imageUrl.length ? itemManagerValues.imageUrl : selectedItem.imageUrl,
      name: itemManagerValues.name.length ? itemManagerValues.name : selectedItem.name,
      rarity: itemManagerValues.rarity.length ? itemManagerValues.rarity : selectedItem.rarity
    };

    this.setState({ itemManagerPending: true });

    const { category, id, name, imageUrl, rarity } = updatedItem;

    this.db.collection('item-shop').doc(id).set({ category, id, name, imageUrl, rarity }, { merge: true })
    .then(() => {
      this.updateAlgoliaItem({category, id, name, imageUrl, rarity});
      this.getNextManagedItem();
    });
  }

  updateItemShop = () => {
    const { values: { newItemsJSON } } = this.state;
    const newItems = JSON.parse(newItemsJSON);

    if (!newItems.length) return;

    this.setState({ pendingUpdate: true });

    this.db.collection('item-shop').doc('aaa-meta').update({
      current_items: newItems,
      last_updated: firebase.firestore.FieldValue.serverTimestamp()
    }).then(() => {
      this.setState({ values: { ...this.state.values, newItemsJSON: '' }, pendingUpdate: false });

      newItems.forEach(item => {
        const { imageUrl, name, rarity, vBucks: price } = item;
        const id = kebabCase(name);

        this.db.collection('item-shop').doc(id).set({
          imageUrl,
          name,
          rarity,
          price,
          last_seen: moment().format('D MMMM YYYY')
        }, { merge: true });
      });
    });
  }

  bulkUpdateItems = () => {
    const { values: { newBulkItemsJSON } } = this.state;
    const newItems = JSON.parse(newBulkItemsJSON);

    if (!newItems.length) return;

    this.setState({ pendingBulkUpdate: true });

    newItems.forEach(item => {
      this.db.collection('item-shop').doc(item.id).set({
        ...item
      }, { merge: true }).then(() => {
        this.setState({ pendingBulkUpdate: false }, () => this.clearValues());
      });
    });
  }

  quickUpdateItemShop = async () => {
    const url = 'https://us-central1-fortnite-dashboard-b545a.cloudfunctions.net/update-item-shop';
    const itemInit = {
      method: 'GET',
      mode: 'no-cors'
    }

    this.setState({ pendingQuickUpdate: true });

    await this.db.collection('wishlists').get().then((rawWish => {
      if (rawWish.size) {

        // Delete documents in a batch
        let batch = this.db.batch();

        rawWish.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });

        return batch.commit().then(() => {
          return rawWish.size;
        });
      }
    }));

    const itemRequest = new Request(url, itemInit);

    await fetch(itemRequest);

    this.setState({ pendingQuickUpdate: false });
  }

  handleChange = (event, type) => {
    const state = {...this.state};

    state[type] = event.target.value;

    this.setState({...state});
  }

  handleItemChange = (event, target) => {
    const { itemValues } = this.state;

    itemValues[target] = event.target.value;

    this.setState({ itemValues });
  }

  handleItemManagerChange = (event, target) => {
    const { itemManagerValues } = this.state;

    itemManagerValues[target] = event.target.value;

    this.setState({ itemManagerValues });
  }

  clearValues = () => {
    this.setState({
      itemValues: {
        category: ''
      }
    });
  }
}
