import React from 'react';

import './styles.scss';

const TestPage = () => {
  // const [data, setData] = useState();

  // useEffect(() => {

  // }, []);

 return <div className="test-page-container">hi</div>;
};

export default TestPage;  