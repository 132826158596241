import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { kebabCase } from 'lodash';

import { firebaseConfig } from '../constants.js';

import { Button, TextField } from '@material-ui/core';

import Updater from '../components/Updater/index';

import './styles.scss';

export default function Missions(props) {
  const firebaseApp = firebase.apps && firebase.apps.length ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();

  const { currentSeason, missions, refreshMissions } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [pendingCreateMission, setPendingCreateMission] = useState(false);
  const [pendingUpdateMission, setPendingUpdateMission] = useState(false);
  const [newMissionName, setNewMissionName] = useState('');
  const [newMissionId, setNewMissionId] = useState('');
  const [newMissionImage, setNewMissionImage] = useState('');
  const [newMissionPriority, setNewMissionPriority] = useState('');
  const [season, setSeason] = useState(currentSeason);
  const [selectedMission, setSelectedMission] = useState();

  async function submitNewMission() {
    setPendingCreateMission(true);

    await db.collection('missions').doc(newMissionId).set({
      active: false,
      id: newMissionId,
      image_source: newMissionImage,
      name: newMissionName,
      priority: newMissionPriority,
      season
    })
    .then(() => {
      refreshMissions();
      setPendingCreateMission(false);
      resetNewMissionFields();
    })
    .catch(err => {
      setPendingCreateMission(false);
      setErrorMessage(`Unable to create mission: ${err}`);
    });
  }

  async function updateMission() {
    if (!selectedMission) return;

    await db.collection('missions').doc(selectedMission.id).update({
      active: selectedMission.active,
      image_source: selectedMission.image_source,
      name: selectedMission.name,
      priority: selectedMission.priority
    })
    .then(() => {
      refreshMissions();
      setPendingUpdateMission(false);
      setSelectedMission(undefined);
    })
    .catch(err => {
      setPendingCreateMission(false);
      setErrorMessage(`Unable to create mission: ${err}`);
    });
  }

  async function toggleActivation() {
    await db.collection('missions').doc(selectedMission.id).update({
      active: !selectedMission.active
    })
    .then(() => refreshMissions);
  }

  function resetNewMissionFields() {
    setNewMissionName('');
    setNewMissionId('');
    setNewMissionImage('');
    setNewMissionPriority('');
  }

  function handleMissionNameUpdate(event) {
    setNewMissionName(event.target.value);
    setNewMissionId(kebabCase(event.target.value));
  }

  useEffect(() => {
    if (!season) {
      db.collection('misc').doc('current-season').get()
      .then(seasonData => {
        if (seasonData) {
          const { value } = seasonData.data();

          setSeason(value);
        }
      })
    }
  }, [season, db]);

  return (
    <div className="missions-container">
      {Boolean(errorMessage.length) && 
        <div className="banner error">{errorMessage}</div>
      }

      <header className={(classnames({ 'show': season !== undefined }))}>
        <span>Season</span>
        <span>{season}</span>
      </header>

      <Updater
        id="create-mission"
        className="create-mission"
        fields={[
          {
            id: 'mission-name',
            element: TextField,
            props: {
              className: 'input',
              label: 'Mission name',
              value: newMissionName,
              onChange: handleMissionNameUpdate
            }
          },
          {
            id: 'mission-type',
            element: TextField,
            props: {
              className: 'input',
              label: 'Pathname',
              value: newMissionId,
              onChange: event => setNewMissionId(event.target.value)
            }
          },
          {
            id: 'mission-image',
            element: TextField,
            props: {
              className: 'input',
              label: 'Image Source',
              value: newMissionImage,
              onChange: event => setNewMissionImage(event.target.value)
            }
          },
          {
            id: 'mission-priority',
            element: TextField,
            props: {
              className: 'input',
              label: 'Priority',
              value: newMissionPriority,
              onChange: event => setNewMissionPriority(+event.target.value)
            }
          }
        ]}
        submitDisabled={!newMissionName || !newMissionId || !newMissionImage || typeof newMissionPriority !== 'number'}
        onSubmit={submitNewMission}
        submitClass="submit-mission"
        submitContent={<>Submit New Mission {pendingCreateMission && <i className="material-icons spinner">autorenew</i>}</>}
      />

      <div className="missions-list">
        <h4>Season {season} Missions</h4>
        {missions.sort((a, b) => a.priority - b.priority).map(mission => (
          <div key={mission.id} onClick={() => setSelectedMission(mission)} className={classnames('mission', {
            'active': mission.active,
            'selected': mission.id === selectedMission?.id
          })}>
            {mission.id === selectedMission?.id 
              ? <>
                  <p>Name:</p><input type="text" value={selectedMission.name} className="edit-name" onChange={event => setSelectedMission({ ...selectedMission, name: +event.target.value })} />
                  <p>Priority:</p><input type="text" value={selectedMission.priority} onChange={event => setSelectedMission({ ...selectedMission, priority: event.target.value })} />
                
                  <Button className="activation" onClick={toggleActivation}>{selectedMission.active ? 'Deactivate' : 'Activate'}</Button>
                  <Button onClick={updateMission}>{pendingUpdateMission ? <i className="material-icons spinner">autorenew</i> : 'Submit'}</Button>
                </>
              : <div className="mission-name">{mission.name}</div>
            }
          </div>
        ))}
      </div>
    </div>
  );
}